import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import {
    Box, Container, TextField, Typography,
    Button, List, MenuItem, ListItemText, Backdrop, CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import { decryptData } from '../../utils/cipherhelper';
import { edit_job } from '../common/requestFormat';

const validatedescrition = (val) => {
    const charRegex = /(^$)|(^[a-z\d\-_,.\s]+$)/i;    
    return charRegex.test(val);
}

const EditJob = () => {

    const location = useLocation();
    const context = location.state
    const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
    const token = oidcUser.access_token;
    const navigate = useNavigate();
    const [isFetching, setIsfetching] = useState(false);
    const intialPayload = {
        userid: context.userid,
        jobid: context.jobid,
        issueid: context.issueid,
        description: context.description,
        oldenddate: context.enddate,
        enddate: context.enddate
    }
    const [payload, setPayload] = useState(intialPayload);    

    const handleChange = (event) => {
        setPayload(prevState => ({
            ...prevState,
            issueid: event.target.value
        }))
    }

    const handledescChange = (event) => {
        if (validatedescrition(event.target.value)) {
            setPayload(prevState => ({
                ...prevState,
                description: event.target.value
            }))
        } else {
            return;
        }
    }

    const handleenddateChange = (event) => {
        if (validatedescrition(event.target.value)) {
            setPayload(prevState => ({
                ...prevState,
                enddate: event.target.value
            }))
        } else {
            return;
        }
    }
    //axios call for fetching search results
    const updateJob = () => {
        setIsfetching(true);
        axios(edit_job(payload, token))
            .then(() => {
                setIsfetching(false);
                navigate('/app/viewjob', { replace: false })
            })
            .catch(() => {
                setIsfetching(false);
            });
    }


    return (
        <Container maxWidth={false}
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                height: 'flex',
                alignContent: 'flex-start',
                spacing: 2,
                paddingRight: '10px!important',
                paddingLeft: '15px!important'

            }}
        >
            <Backdrop id="progress" open={isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ mt: 3, mb: 3 }}>
                <Typography
                    color="textPrimary"
                    variant="h5"
                >
                    Edit Job Info
                </Typography>
                {/* <Typography variant="h5" sx={{ marginRight: 'auto' }}>Edit Job</Typography> */}
            </Box>
            <Box

                sx={{
                    borderRadius: 1.5,
                    backgroundColor: '#fff',
                    padding: '15px',
                    boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                    maxWidth: '100%'
                }}>
                <List >
                    <MenuItem  >
                        <ListItemText>Job ID: {context.jobid}</ListItemText>
                    </MenuItem>

                    <MenuItem >
                        <ListItemText>Object: {context.object}</ListItemText>
                    </MenuItem>
                    <MenuItem  >
                        <ListItemText>Operation: {context.operation}</ListItemText>
                    </MenuItem>
                    <MenuItem  >
                        <ListItemText>Frequency: {context.frequency}</ListItemText>
                    </MenuItem>
                    <MenuItem  >
                        <ListItemText>Start Date: {context.startdate}</ListItemText>
                    </MenuItem>

                    <TextField
                        id="issueid"
                        label="Issue Id"
                        placeholder="Valid iTAM Incident or Jira Id [CDMC,DQR, ECO, DIG etc]"
                        onChange={handleChange}
                        value={payload.issueid}
                        fullWidth
                        required
                        sx={{ width: '100%', marginTop: '15px' }}
                        inputProps={{
                            maxLength: 10
                        }} />


                    <TextField
                        id="description"
                        label="Job Description "
                        placeholder="Please note Description max length 100 characters and allowed characters [aA-zZ0-9-_,.space]"
                        value={payload.description}
                        onChange={handledescChange}
                        fullWidth
                        multiline
                        required
                        sx={{ width: '100%',  marginTop: '15px'  }}
                        inputProps={{
                            maxLength: 100
                        }}
                    />

                    <TextField
                        id="enddate"
                        label="End Date"
                        type="date"
                        margin="normal"
                        required
                        value={payload.enddate}
                        onChange={handleenddateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </List>

            </Box>
            <Box
                sx={{
                    marginTop: '5px',
                    maxWidth: '100%',
                    py: 2
                }}>
                
                <Button
                    color="primary"
                    size="large"
                    type="submit"
                    fullWidth
                    variant="contained"
                    data-testid='btn-update'
                    onClick={updateJob}
                    sx={{
                        marginTop: '5px',
                        background: `linear-gradient(to right, #1488cc, #2b32b2)`,
                    }}
                    disabled={payload.issueid === '' || payload.description === '' || payload.enddate == ''}
                >Update</Button>

                <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    data-testid='btn-back'
                    onClick={(e) => { e.preventDefault(); navigate('/app/viewjob', { replace: false }) }}
                    sx={{
                        marginTop: '5px',
                        background: `linear-gradient(to right, #1488cc, #2b32b2)`,
                    }}

                >Cancel</Button>
            </Box>
        </Container>


    );
};

export default EditJob;
