import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

const products = [
  {
    id: uuidv4(),
    name: 'Remediation : 10 pm - 6 am',
    imageUrl: '/static/images/products/product_1.png'
  },
  {
    id: uuidv4(),
    name: 'Outage : 07/10/2021 => 10 pm - 5 am ',
    imageUrl: '/static/images/products/product_2.png'
  },
  {
    id: uuidv4(),
    name: 'Outage : 06/12/2021 => 1 am - 5 am ',
    imageUrl: '/static/images/products/product_3.png'
  }
];

const JobConfig = (props) => (
  <Card {...props} sx= {{
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    minWidth: '100%'
  }}>
    <CardHeader
      sx={{paddingLeft:'15px'}}
      subtitle={`${products.length} in total`}
      title="Remediation & Outage Window"
    />
    <Divider />
    <List>
      {products.map((product, i) => (
        <ListItem
          sx={{color: '#000'}}
          divider={i < products.length - 1}
          key={product.id}
        >
          <ListItemAvatar>
            <img
              alt={product.name}
              src={product.imageUrl}
              style={{
                height: 48,
                width: 48
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={product.name}
          />
        </ListItem>
      ))}
    </List>
    <Divider />
  </Card>
);

export default JobConfig;
