import * as React from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer

} from '@material-ui/core';


const FAQMenu = () => {

    return (
        <TableContainer sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }}>FAQ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow key="1"

                    ><TableCell sx={{ color: 'white' }}>
                            Q: What systems are supported for Data Remediation currently?<br />
                            A: Currently, ONLY Salesforce - Account, Contact, AccountContactRelation, User, Asset, Order, OrderItem, custom Sync objects.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }} >
                            Q: What are the different remediation types supported?<br />
                            A: Options for a data remediation job are…<br />
                            -	Sync: Data updated in Salesforce & BAU 2-Way Datasync to TCM/RCRM/CDBoR etc<br />
                            -	NoSync: Data updated in Salesforce ONLY (not synced to other systems or not needed)<br />
                            -	SyncOverwrite: Skip Date Validation else same as Sync (forced update)<br />
                            -	NoSyncOverwrite: Skip Date Validation else same as nosync (forced update)<br />
                            -	Retrigger: Submitted Record for BAU 2-Way DataSync (without any update to Salesforce record)<br />
                            -	Insert: Create a New Salesforce Record (limited to certain entities - Restricted)<br />
                            -	Delete: Delete a Salesforce Record (or mark for deletion) - limited to certain entities - Restricted<br />
                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }} >
                            Q: Is there a fixed remediation schedule for data remediation execution?<br />
                            A: Current BAU remediation schedule is Daily 11PM - 8AM AEST

                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }} >
                            Q: Do I have to schedule a new job for ongoing remediation work?<br />
                            A: No, Jobs could be scheduled as<br />
                            -	Daily: Job scheduled to run daily<br />
                            -	Weekly: Job scheduled to run weekly on the same day of the week<br />
                            -	Monthly: Job scheduled to run monthly on the same day of the month<br />
                            -	Note: Remediation ONLY performed if a remediation file is uploaded on the scheduled day<br />


                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white', border: 0 }} >
                            Q: Are there any guidelines for remediation date file?<br />
                            A: Yes, the remediation file varies slightly by Job type - please refer  below<br />

                            Sync/NoSync: Mandatory Id (record id based on Job Object) and DataExtractDate (yyyy-mm-dd or yyyy-mm-ddThh:mm:ss)<br />
                            SyncOverwrite/NoSyncOverwrite: Mandatory -	Id<br />
                            Create:	Restricted & depends on the object - please reach out to the Team for Support<br />

                        </TableCell>

                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>

    )
};

export default FAQMenu;