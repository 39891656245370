import React from 'react';
import { Box } from '@material-ui/core';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip, 
    Legend,

} from "recharts";


const ScheduledGraph = (props) => {    

    return (<Box
        sx={{
            borderRadius: 1.5,
            backgroundColor: '#fff',
            padding: '5px',
            maxWidth: '100%'
        }}><ResponsiveContainer width="100%" height={250}>
        <BarChart
                        
                        width={800}
                        height={250}
                        data={props.graphData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20
                        }}
                    >
                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                        <XAxis interval={0} angle={0} dataKey="date" type="category" scale="band" />
                        <YAxis type="number" hide={true}/>
                        <Tooltip />
                        <Legend   wrapperStyle={{bottom:5}}/>
            
                        <Bar dataKey="other" barSize={20}  stackId="a" fill="#413ea0" >
                            {/* <LabelList dataKey="other" position="center" /> */}
                        </Bar>
                        <Bar dataKey="sync" barSize={20} stackId="a" fill="red" >
                            {/* <LabelList dataKey="sync" position="center" /> */}
                        </Bar>
                        <Bar dataKey="available" barSize={20} stackId="a" fill="green">
                            {/* <LabelList dataKey="available" position="center" /> */}
                        </Bar>
            
                    </BarChart>
     </ResponsiveContainer>


    </Box>)
}

export default ScheduledGraph;