import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
//import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useQuery } from 'react-query';
import { detailedView_request } from '../common/requestFormat';
import { useNavigate, useParams } from "react-router-dom";
import Spinner from '../../components/common/spinner';
import StatusMessage from '../../components/common/statusMessage.js';
import Download from './download.js'
import _ from 'lodash';
import {
  Box,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Container,
  Typography
} from '@material-ui/core';
import {decryptData} from '../../utils/cipherhelper';

const DetailedView = () => {
  let navigate = useNavigate();
  const params = useParams();
  const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
  const token = oidcUser?.access_token;
  let input = {
    jobid: params.jobid
  }
  const generateReport = (jobreport) => {
    let detailReport = [];
    _.forEach(jobreport, function (value, key) {
      let detail = jobreport[key].detail;
      let executiondate = jobreport[key].executiondate;
      _.forEach(detail, function (report) {
        detailReport.push({
          executionDate: executiondate,
          success: report.success,
          invalid: report.invalid,
          failed: report.failed,
          cancelled: report.cancelled,
          skipped: report.skipped,
          jobtype: report.jobtype,
          unprocessed: report.unprocessed,
          bucket: report.bucket,
          key: report.key
        })
      })
    });
    return detailReport;
  }
  const fetchDetailedReport = async () => axios(detailedView_request(input, token))
    .then(response => {
      if(response.data.status === 204){
        setEmptyData('error');
        return;
      }
      return response.data.body.executiondetails
    })


  const {
    data,    
    status
  } = useQuery('jobreport', fetchDetailedReport,
    { keepPreviousData: true, refetchOnWindowFocus: false })
  const [emptyData, setEmptyData] = useState(false);  
  const detailedReport = generateReport(data);  

  return (
    <>
      <Helmet>
        <title>Job Detail - Execution Sumary | BatchFactory</title>
      </Helmet>
      <Container maxWidth={false}
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          height: 'flex',
          alignContent: 'flex-start',
          spacing: 2
        }}
      >
        <Box sx={{ mt: 3, mb: 3 }}>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Job Execution Detail
          </Typography>  
          <Typography
            color="textPrimary"
            variant="h7"
          >
            Job Id: {input.jobid}
          </Typography>        
        </Box>
        <Box
          sx={{
            borderRadius: 1.5,
            backgroundColor: '#fff',
            padding: '15px',
            boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
            maxWidth: '100%'
          }}>
          {
            status === "loading" ? <Spinner />
              : status === "error" ? <StatusMessage
                severity="error"
                message="Unable to fetch Detail Execution Summary for this Job. Please retry after some time."
                minwidth={600}
              />
                : status === "success" && !emptyData  ? <>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell>Execution date</TableCell>
                        <TableCell>JobType</TableCell>
                        <TableCell>Success</TableCell>
                        <TableCell>Failed</TableCell>
                        <TableCell>Invalid</TableCell>
                        <TableCell>Cancelled </TableCell>
                        <TableCell>Skipped</TableCell>
                        <TableCell>Unprocessed</TableCell>
                        <TableCell>Download</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailedReport.map((item, index) => (
                        <TableRow key={index} data-testid={index}>
                          <TableCell>
                            {item.executionDate}
                          </TableCell>
                          <TableCell>
                            {item.jobtype}
                          </TableCell>
                          <TableCell>
                            {item.success}
                          </TableCell>
                          <TableCell>
                            {item.failed}
                          </TableCell>
                          <TableCell>
                            {item.invalid}
                          </TableCell>
                          <TableCell>
                            {item.cancelled}
                          </TableCell>
                          <TableCell>
                            {item.skipped}
                          </TableCell>
                          <TableCell>
                            {item.unprocessed}
                          </TableCell>
                          <TableCell>
                            <Download item={item}/>
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
                  : <StatusMessage
                  severity="error"
                  message="No Details to view"
                  minwidth='inherit'
                />
          }
        </Box>
        <Box>
          <Button
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            data-testid='btn-back'
            //onClick= {(e) => {e.preventDefault(); navigate(-1)}} ** Navigation not proper, and only working on second click
            onClick={(e) => { e.preventDefault(); navigate('/app/viewjob', { replace: false }) }}
            sx={{

              background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            }}
          >
            Back
          </Button>
        </Box>
      </Container>
    </>
  )
}

export default DetailedView;