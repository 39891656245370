import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
//import { JobContextProvider } from '../components/createjob/job-context';

function AuthenticatedContent() {
    useEffect(() => {
        document.title = 'CDM | BatchFactory'
    })

    const queryClient = new QueryClient();
        return (
            <Grid container sx={{paddingLeft: '240px', paddingTop: '90px', paddingRight:'20px'}}>
                <QueryClientProvider client={queryClient}>
                    <Outlet />
                </QueryClientProvider>
            </Grid>
    );
}

export default AuthenticatedContent;