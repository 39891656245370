import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
//import { useReactOidc } from '@axa-fr/react-oidc-context';
import CollapsibleJobs from './collapsiblejobs';
import { useParams } from "react-router-dom";
import { decryptData } from '../../utils/cipherhelper';
import { useEffect } from 'react';
import { view_searchjob_details } from '../common/requestFormat';
import {
    Box,
    Button,
    Backdrop, CircularProgress,
    Container,
    Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import StatusMessage from '../../components/common/statusMessage.js';

const SearchDetailedView = () => {   
    let navigate = useNavigate();

    const params = useParams();   
    const initialJobs = [];
    const [jobs, setJobs] = useState(initialJobs);
    const token = decryptData(sessionStorage.getItem('oidcString')).access_token;
    // const user = decryptData(sessionStorage.getItem('oidcString'));
    const [error, setError] = useState(false);
    const [isFetching, setIsfetching] = useState(true);

    const input = {
        "random_id": params.record_id
    }
    useEffect(() => {
        setIsfetching(true)
        searchAxios(input);
    }, [])



    const searchAxios = (input) => {
        axios(view_searchjob_details(input, token))
            .then((result) => {
                setIsfetching(false);                
                setJobs(result.data.body.executiondetails)
            })
            .catch(() => {                
                setIsfetching(false);
                setError(true)
            });
    }

    if (error) return (<StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." />)

    return (
        <>
            <Helmet>
                <title>Job Detail - Execution Details | BatchFactory</title>
            </Helmet>
            <Container maxWidth={false}
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    height: 'flex',
                    alignContent: 'flex-start',
                    spacing: 2
                }}
            ><Backdrop open={isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        Job Search Detail
                    </Typography>
                    {/* <Typography
            color="textPrimary"
            variant="h7"
          >
            Search Random Id:
          </Typography>         */}
                </Box>
                <Box
                    sx={{
                        borderRadius: 1.5,
                        backgroundColor: '#fff',
                        padding: '15px',
                        boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                        maxWidth: '100%'
                    }}>
                    <CollapsibleJobs isFetching={isFetching} jobs={jobs} />
                </Box>
                <Box sx={{ marginTop: '10px' }}>
                    <Button
                        color="primary"

                        size="large"
                        type="submit"
                        variant="contained"
                        data-testid='btn-back'
                        onClick={(e) => { e.preventDefault(); navigate('/app/searchjob', { replace: false }) }}
                        sx={{

                            background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        }}
                    >
                        Back
                    </Button>
                </Box>
            </Container>
        </>
    )
}

export default SearchDetailedView;