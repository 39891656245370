import * as React from 'react';
import {
    MenuItem,
    ListItemText,
    List, Checkbox,Box

} from '@material-ui/core';
//import { useEffect } from 'react';
//import { useState } from 'react';
import StatusMessage from '../../components/common/statusMessage.js';


export default function MultipleSelectCheckmarks(props) {


    return (
        <Box
            sx={{
                borderRadius: 1.5,
                backgroundColor: '#fff',
                marginLeft: '20px',
                marginTop: '5px',
                boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                maxWidth: '100%'
            }}>
            {props.data?.length === 0 && (<StatusMessage severity="error" message="Sorry, No Fields available." />)}
            {props.data?.length > 0 && (<List sx={{ height: 400, overflowY: 'auto' }} lg={12}>

                {props.data.map((nameItem) => (
                    <MenuItem sx={{ height: 30 }} key={nameItem.name} value={nameItem.name} lg={6}>
                        <Checkbox inputProps={{ 'aria-label': 'controlled', 'data-testid': nameItem.name }}
                            onChange={(event) => props.updateFields(event, nameItem.name)}
                            checked={nameItem.mandatory} />
                        <ListItemText primary={nameItem.name} />
                    </MenuItem>

                ))}
            </List>)}
        </Box>

    );
}

