import React from 'react';
import { Box, Collapse } from '@material-ui/core';
import {
  Table, IconButton,  TableCell, TableHead,TableBody,
   TableRow
} from '@material-ui/core';
import InnerRow from './remediationresults';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import StatusMessage from '../../components/common/statusMessage';
const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', borderTop: '1px solid lightgray' } }}>
                <TableCell style={{width:'5%'}}>
                    <IconButton
                        aria-label="expand row"
                        id="toggle-row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </TableCell>
                <TableCell aling="left">{row.record_id}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {!row.status && (<StatusMessage severity="error" message="No results" />)}
                            {row.status && (<Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Job ID</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell>Remediation Date(s)</TableCell>
                                        <TableCell>System</TableCell>
                                        <TableCell>Object</TableCell>
                                        <TableCell>Operation</TableCell>
                                        <TableCell>Frequency</TableCell>
                                        {/* <TableCell>Start Date</TableCell>
                                        <TableCell>End Date</TableCell> */}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.exce_details.map((job) => (
                                        <InnerRow key={job.job_id} row={job} />

                                    ))}
                                </TableBody>
                            </Table>)}

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default Row;