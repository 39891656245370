import React from 'react';
import {
    Drawer, Box, List
} from '@material-ui/core';
import theme from '../styles/theme';
import SidebarItems from './SidebarItems';
import NavItem from './NavItem';
import SidebarHeader from './SidebarHeader';

const AuthenticatedSidebar = () => {
    return (
        <>
            <Drawer variant='permanent'>
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
                >
                    <SidebarHeader />
                    <Box sx={{ p: '5px' }}>
                        <List>
                            {SidebarItems.map((item) => (
                                <NavItem color={theme.palette.primary.light}
                                    href={item.href}
                                    key={item.title}
                                    title={item.title}
                                    icon={item.icon}
                                />
                            ))}
                        </List>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            </Drawer>
        </>
    );
};


export default AuthenticatedSidebar;
