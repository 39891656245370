import React, { useState } from 'react';
import {
  Box, Tabs, Tab, Container, Grid, Backdrop, CircularProgress,
  Accordion, Typography, AccordionSummary, AccordionDetails, TextField
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { reporting } from '../common/requestFormat';
import { useEffect } from 'react';
import StatusMessage from '../../components/common/statusMessage.js';
import { decryptData } from '../../utils/cipherhelper';
import OverallGraph from './overall';
import ObjectGraph from './objectgraph';
import UserGraph from './usergraph';
import IssueidGraph from './issueidgraph';

const tabMap = new Map();
tabMap.set(0, 'overall');
tabMap.set(1, 'object');
tabMap.set(2, 'user');
tabMap.set(3, 'issue_id');




function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div>
      {value === index && (<div>{children}</div>)}
    </div>
  )
}

const retrievePastDate = (keyvalue, days) => {
  let selDate = new Date(keyvalue);
  selDate.setDate(selDate.getDate() - days)
  return selDate.toISOString().slice(0, 10);
}

const setNextDays = (keyvalue, current) => {
  let selDate = new Date(keyvalue);
  selDate.setDate(selDate.getDate() + 14)
  if (selDate <= new Date(current)) {
    return selDate.toISOString().slice(0, 10);
  } else {
    return current
  }

}



const Reporting = () => {
  const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
  const userid = oidcUser?.profile.sub;
  const token = oidcUser?.access_token;
  const user = [{ label: 'Me', value: userid },
  { label: 'All', value: 'all' }]

  const count = [{ label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' }]

  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  const currentDate = retrievePastDate(new Date(Date.now() - tzoffset), 1)
  // const [sdate] = useState(defaultStartDate(currentDate, 14))
  const [isfetching, setIsfetching] = useState(false);
  const [reportData, setReportData] = useState([]);
  const intialFilter = {
    "start_date": retrievePastDate(currentDate, 14),
    "end_date": currentDate,
    "userid": userid,
    "type": "overall",
    "record_count": 10
  }
  const [payload, setPayload] = useState(intialFilter)

  const overall = () => {
    setIsfetching(true);
    axios(reporting(payload, token)).then((result) => {
      setIsfetching(false);
      setReportData(result.data.body);
    }).catch(() => {     
      setIsfetching(false);
    })
  }
  const [value, setValue] = useState(0);

  const handleTabs = (event, val) => {    
    setValue(val);
    setPayload(prevState => ({
      start_date: prevState.start_date,
      end_date: prevState.end_date,
      userid: prevState.userid,
      type: tabMap.get(val)
    }))
  };

  const handleedChange = (keyvalue) => {
    setPayload(prevState => ({
      ...prevState,
      start_date: retrievePastDate(keyvalue.currentTarget.value, 14),
      end_date: keyvalue.currentTarget.value
    }));
  }
  const handlesdChange = (keyvalue) => {
    let endDate = setNextDays(keyvalue.currentTarget.value, currentDate);
    setPayload(prevState => ({
      ...prevState,
      start_date: keyvalue.currentTarget.value,
      end_date: endDate
    }));

  }

  const handleuserChange = (keyvalue) => {
    setPayload(prevState => ({
      ...prevState,
      start_date: prevState.start_date,
      end_date: prevState.end_date,
      userid: keyvalue.currentTarget.value
    }));


  }

  const handleCountChange = (keyvalue) => {
    setPayload(prevState => ({
      ...prevState,
      record_count: keyvalue.currentTarget.value
    }));

  }


  useEffect(() => {
    overall();
  }, [payload])





  return (
    <>
      <Helmet>
        <title>Reporting</title>
      </Helmet>
      <Container maxWidth={false}
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          height: 'flex',
          alignContent: 'flex-start',
          spacing: 2,
          paddingRight: '10px!important',
          paddingLeft: '15px!important'

        }}
      >
        <Backdrop open={isfetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ flexGrow: 1, marginBottom: '10px' }}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="report-filter"
            ><Box sx={{ display: 'flex', width: '100%' }}>
                <Typography variant="h6" sx={{ marginRight: 'auto' }}>Select Filter</Typography>

              </Box>

            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction='row' spacing={2}>
                <Grid item lg={4}>
                  <TextField
                    id="startdate"
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    margin="normal"
                    onChange={handlesdChange}
                    required
                    value={payload.start_date}
                    sx={{ width: '100%' }}
                    data-testid='report-sd'
                    inputProps={{
                      max: currentDate
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    id="enddate"
                    label="End Date"
                    type="date"
                    margin="normal"
                    sx={{ width: '100%' }}
                    value={payload.end_date}
                    required
                    onChange={handleedChange}
                    data-testid='report-ed'
                    inputProps={{

                      max: currentDate
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                </Grid>

                <Grid item lg={2}>
                  <TextField sx={{ width: '100%' }}
                    id="userSelect"
                    label="Select User"
                    name="user"
                    margin="normal"
                    value={payload.userid}
                    onChange={handleuserChange}
                    required
                    select
                    inputProps={{ 'data-testid': 'userfilter' }}
                    SelectProps={{ native: true }}
                    fullWidth
                  >
                    {user.map((option) => (
                      <option data-testid="select-user-option"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={2}>
                  <TextField sx={{ width: '100%' }}
                    id="countSelect"
                    label="Select Top"
                    name="record_count"
                    margin="normal"
                    value={payload.record_count }
                    onChange={ handleCountChange }
                    required
                    select
                    InputLabelProps={{
                      shrink: true,
                  }}
                    inputProps={{ 'data-testid': 'countfilter'}}
                    SelectProps={{ native: true }}
                    fullWidth
                  >
                    {count.map((option) => (
                      <option data-testid="select-user-option"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            borderRadius: 1.5,
            backgroundColor: '#fff',
            padding: '15px',
            boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
            maxWidth: '100%'
          }}>
          <Grid container spacing={2} >
            <Grid item md={6} xs={12}>
              <Tabs value={value} onChange={handleTabs}>
                <Tab label="Overall" />
                <Tab label="Object" />
                <Tab label="User" />
                <Tab label="Issueid" />
              </Tabs>
            </Grid>
            <Grid item md={6} xs={12}></Grid>
            <Grid item md={12}>
              <TabPanel value={value} index={0}>
                {reportData?.length > 0 && (<OverallGraph graphData={reportData} />)}
                {!isfetching && reportData?.length === 0 && (<StatusMessage severity="error" message="Sorry, No data availabale for the selected conditions!" />)}
              </TabPanel>

              <TabPanel value={value} index={1}>
                <div>
                  {reportData?.length > 0 && (<ObjectGraph graphData={reportData} />)}
                  {!isfetching && reportData?.length === 0 && (<StatusMessage severity="error" message="Sorry, No data availabale for the selected conditions!" />)}
                </div>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <div>
                  {reportData?.length > 0 && (<UserGraph graphData={reportData} />)}
                  {!isfetching && reportData?.length === 0 && (<StatusMessage severity="error" message="Sorry, No data availabale for the selected conditions!" />)}
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div>
                  {reportData?.length > 0 && (<IssueidGraph graphData={reportData} />)}
                  {!isfetching && reportData?.length === 0 && (<StatusMessage severity="error" message="Sorry, No data availabale for the selected conditions!" />)}
                </div>
              </TabPanel>

            </Grid>
          </Grid>

        </Box>
      </Container>
    </>
  )
}

export default Reporting;
