import React from 'react';
import { Navigate } from 'react-router-dom';
import PublicApp from './layout/PublicApp';
import Dashboard from './layout/Dashboard';
import ShowJob from './views/ShowJob';
import JobConfig from './components/dashboard/JobConfig';
import NotFound from './views/NotFound';
import Auth from './views/Auth';
import Home from './views/Home';
import NotAuthenticated from './components/auth/NotAuthenticated';
import AuthenticatedApp from './layout/AuthenticatedApp';
import ViewJobRoot from './components/viewjob/viewJobRoot';
import DetailedView from './components/viewjob/detailedView';
import CreateJob from './views/CreateJob';
import JobSummary from './views/JobSummary';
import ScheduleJob from './components/scheduledjob/schedulejob';
import GenerateTemplate from './components/generatetemplate/generatetemplate';
import SearchJob from './components/search/searchjob' 
import SearchDetailedView from './components/search/searchdetailview';
import EditJob from './components/viewjob/editjob'
import Reporting from './components/reporting/reporting';

const routes = [
  {
    path: '/app',
    element: <AuthenticatedApp />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'createjob', element: <CreateJob /> },
      { path: 'jobSummary', element: <JobSummary /> },
      { path: 'viewjob', element: <ViewJobRoot />},
      { path: 'showjob', element: <ShowJob /> },
      { path: 'detailedView/:jobid', element: <DetailedView /> },
      { path: 'settings', element: <JobConfig /> },
      { path: 'schedulejob', element: <ScheduleJob /> },
      { path: 'generateTemplate', element: <GenerateTemplate /> },
      { path: 'searchjob', element: <SearchJob /> },
      { path: 'searchdetail/:record_id', element: <SearchDetailedView /> }, 
      { path: 'editjob', element: <EditJob /> },    
      { path: 'reporting', element: <Reporting /> },    
      { path: '*', element: <Navigate to="/404" /> }
    ],
  },
  {
    path: '/',
    element: <PublicApp />,
    children: [      
      { path: '/', element: <Home /> },
      { path: '404', element: <NotFound /> },
      { path: 'auth', element: <Auth /> },
      { path: 'unauth', element: <NotAuthenticated /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
