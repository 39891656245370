import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import StatusMessage from '../components/common/statusMessage';
import {
  Box,
  Container,
  Button,
  Backdrop,
  TextField,
  Typography
} from '@material-ui/core';
import * as Constants from '../components/createjob/constants';
import * as Options from '../components/common/requestFormat';
import { decryptData } from '../utils/cipherhelper';
//import { useReactOidc } from '@axa-fr/react-oidc-context';
const setfrequencies = (oidcUser)=>{   
  let role = oidcUser?.profile.groups;
  return role.includes('CDMAdmin')?Constants.Adminfrequencies:Constants.frequencies;

}

const generateJobID = (userid) => {

  const jobdate = new Date().getTime().toString()
  return userid + '-SF-' + jobdate.substring(jobdate.length - 6, jobdate.length)
}


const CreateJob = () => {
  /**
   * Material UI core components can't be tested using jest, Hence unable to cover few lines in UT.
   * But, all the scenarios are tested from GUI testing
  */

  const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
  var frequencies = setfrequencies(oidcUser);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  let token = oidcUser?.access_token;
  const currentDate = new Date().toISOString().slice(0, 10);
  const user = oidcUser?.profile.sub;
  const [operations, setOperations] = useState([]);
  const [statusInput, setStatusInput] = useState(
    {
      severity: '',
      message: ''
    }
  );

  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localDate = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);

  const initialValues = {
    jobid: generateJobID(user),
    userid: user,
    system: 'Salesforce',
    object: 'Account',
    operation: '',
    frequency: 'Daily',
    startdate: localDate,
    enddate: localDate,
    issueid: '',
    description: '',
    status: 'Scheduled',
    email: oidcUser.profile.email,
    username: oidcUser.profile.given_name
  };
  const [payload, setPayload] = useState(initialValues);
  useEffect(() => {
    fetchOperation(initialValues.object);
  }, [])

  useEffect(() => {
    if (operations?.length > 0) {
      setPayload(prevState => ({
        ...prevState,
        operation: operations[0].value
      }))
    }

  }, [operations])

  const fetchOperation = (value) => {
    setOpen(true);
    let input = {
      system: initialValues.system,
      object: value
    }
    let config = Options.fetch_operations(input, token)
    axios(config).then(result => {
      setOpen(false);
      setOperations(result.data.body);
    }, () => {
      let errorMsg = {
        severity: 'error',
        message: 'Sorry, error encountered. Please retry after some time.'
      }
      setStatusInput(errorMsg);
      setOpen(false);
    })
  }

  const charRegex = /^[a-z\d\-_,.\s]+$/i;
  const createjobSchema = Yup.object().shape({
    issueid: Yup.string().required(),
    description: Yup.string()
      .required()
      .matches(charRegex, 'Valid: alphanumeric [aA-zZ0-9-_,.space], max 100 characterss')
      .max(100)
  });

  const handleObjectChange = (e) => {
    setPayload(prevState => ({
      ...prevState,
      object: e.currentTarget.value
    }))
    fetchOperation(e.currentTarget.value);
  }
  const submitHandler = (values) => {
    setOpen(true);
    let errorMsg = {
      severity: 'error',
      message: 'Job creation failed...Please try re-submitting!'
    }

    axios(Options
      .create_request(values, token))
      .then((result) => {
        setOpen(false);        
        if (result.status === 200) {          
          navigate('/app/jobsummary', { state: values, replace: true });
        }
        else {
          setStatusInput(errorMsg);
        }
      })
      .catch(() => {
        setOpen(false);
        setStatusInput(errorMsg);
      });
  }

  return (
    <>
      <Helmet>
        <title>Create Job | BatchFactory</title>
      </Helmet>
      <Container maxWidth={false}
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          height: 'flex',
          alignContent: 'flex-start',
          spacing: 2
        }}
      >
        <Formik
          initialValues={payload}
          onSubmit={submitHandler}
          validationSchema={createjobSchema}
          enableReinitialize={true}
        >
          {({ values, handleChange, dirty, isValid }) => (
            <Form>
              <Backdrop open={open}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Box sx={{ mt: 3, mb: 3 }}>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Create Job
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  *All fields are mandatory
                </Typography>
              </Box>
              <Box
                id="CreateJobForm"
                sx={{
                  borderRadius: 1.5,
                  backgroundColor: '#fff',
                  padding: '15px',
                  boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                  maxWidth: '100%'
                }}>
                {statusInput.severity !== '' &&
                  <StatusMessage severity={statusInput.severity} message={statusInput.message} minwidth={'96%'} />
                }
                <TextField
                  label="Select System"
                  name="system"
                  margin="normal"
                  value={values.system}
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  fullWidth
                >
                  {Constants.systems.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  label="Select Object"
                  name="object"
                  margin="normal"
                  value={values.object}
                  onChange={e => {
                    // call the built-in handleBur
                    handleObjectChange(e)

                  }}
                  required
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                >
                  {Constants.objects.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                {operations?.length > 0 && (<TextField
                  label="Select Operation"
                  name="operation"
                  margin="normal"
                  value={values.operation}
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                >
                  {operations.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>)}
                <TextField
                  label="Select Frequency"
                  name="frequency"
                  margin="normal"
                  value={values.frequency}
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  helperText='Adhoc Plan is for urgent/critical real time remediation - for CDM-Admin profiles ONLY'
                  fullWidth
                >
                  {frequencies.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  id="startdate"
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  margin="normal"
                  required
                  value={values.startdate}
                  onChange={handleChange}
                  inputProps={{
                    min: currentDate
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
                
                  <TextField
                    id="enddate"
                    label="End Date"
                    type="date"
                    margin="normal"
                    required
                    value={values.enddate}
                    onChange={handleChange}
                    inputProps={{
                      min: values.startdate
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                
                {values.frequency === 'Weekly' && (
                  <TextField
                    label="Select start date"
                    name="day"
                    margin="normal"
                    disabled={true}
                    value={new Date(values.startdate).getDay()}
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                  >
                    {Constants.days.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                )}
                {values.frequency === 'Monthly' && (
                  <TextField
                    label="Select start date"
                    name="date"
                    margin="normal"
                    value={new Date(values.startdate).getDate()}
                    onChange={handleChange}
                    disabled={true}
                    select
                    SelectProps={{ native: true }}
                    fullWidth
                  >
                    {Constants.dates.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                )}
                <TextField
                  id="issueid"
                  label="Issue Id"
                  placeholder="Valid iTAM Incident or Jira Id [CDMC,DQR, ECO, DIG etc]"
                  onChange={handleChange}
                  value={values.issueid}
                  fullWidth
                  required
                  inputProps={{
                    maxLength: 20
                  }}
                />
                <TextField
                  id="description"
                  label="Job Description"
                  placeholder="Please note Description max length 100 characters and allowed characters [aA-zZ0-9-_,.space]"
                  value={values.description}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  required
                  inputProps={{
                    maxLength: 100
                  }}
                />
              </Box>
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={!dirty || !isValid}
                  sx={{

                    background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                  }}
                >
                  Create Job
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default CreateJob;
