import * as React from 'react';
import { useState } from 'react';
import {
    MenuItem,
    ListItemText,
    List, Checkbox, Button,
    Accordion, AccordionDetails, AccordionSummary, Box, Typography,
    Backdrop, CircularProgress, Modal

} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Options from '../common/requestFormat';
import axios from 'axios';
import StatusMessage from '../common/statusMessage';
//import { decryptData } from '../../utils/cipherhelper';
const style = {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function FileList(props) {      
    const role = props.user.profile.groups;
    const user = props.user.profile.sub;
    const [openLoader, setOpenLoader] = useState(false);
    const [filesToDelete, setfiles] = useState([]);
    const [errorMsg, setErrorMsg] = useState(false);
    //modal
    const [open, setOpen] = React.useState(false);
    //const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //handle chekcbox change event
    const handleChange = (event, value) => {
        let array = [...filesToDelete];
        if (event.currentTarget.checked) {
            array.push(value);
        } else {
            array.splice(array.indexOf(value), 1);
        }
        setfiles(array);
    }

    const handleDelete = () => {
        setOpen(false);
        setOpenLoader(true)
        let input = {
            "date": props.data.date,
            "files": filesToDelete
        }
        let config = Options.deletefile_request(input, props.user.access_token)
        axios(config).then(() => {
            setfiles([])            
            props.delete();            
            setOpenLoader(false)            
        }, () => {
            setErrorMsg(true);
            setOpenLoader(false)            
        })
    }
    const openModal = () => {
        setOpen(true);
    }
    return (
        <div>
            <Backdrop open={openLoader} sx={{zIndex:'1'}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {props.data.files.length > 0 && (<Accordion disableGutters={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}                    
                    aria-controls="panel1a-content"                    
                    id="file-list-accordion"
                    sx={{ minHeight: 30, margin:0 }}                                     
                ><Box sx={{ display: 'flex', width: '100%' }}>
                        <Typography variant="h7" sx={{ marginRight: 'auto' }}>View Files [adhoc files are not displayed]</Typography>

                    </Box>

                </AccordionSummary>
                <AccordionDetails sx={{margin:0}}>
                    {errorMsg && (<div style={{ width: '95%' }}><StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." /></div>)}
                    <List sx={{}} lg={12}>

                        {props.data.files.map((nameItem) => (
                            <MenuItem id={nameItem} sx={{ height: 30, p: 0 }} key={nameItem} value={nameItem} lg={6} disabled={!(role.includes('CDMAdmin') || nameItem.indexOf(user) != -1)}>
                                <Checkbox inputProps={{ 'aria-label': 'controlled', 'data-testid': nameItem }} onChange={(e) => handleChange(e, nameItem)}

                                />
                                <ListItemText primary={nameItem} />

                            </MenuItem>
                        ))}

                    </List>
                    <Button
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        data-testid={props.data.date}
                        onClick={openModal}
                        disabled={filesToDelete.length == 0}
                        sx={{

                            background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                        }}
                    >Delete</Button>
                </AccordionDetails>
            </Accordion>)}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Delete Confirmation
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure to delete the file(s)?
                    </Typography>
                    <Button color="primary"  variant="contained" sx={{
                        background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    }} onClick={handleDelete}>Yes</Button>
                    <Button  color="primary" variant="contained" sx={{
                        marginLeft:2,
                        background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                    }} onClick={handleClose}>No</Button>
                </Box>
            </Modal>
        </div>
    );
}

