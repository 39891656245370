import React from 'react';

import {
    TableCell,
    TableRow, List, MenuItem


} from '@material-ui/core';

const InnerRow = (props) => {
    const { row } = props;
    const rem_date_list = row.remediation_date.split(',');
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset', borderTop: '1px solid lightgray' } }}>
                <TableCell >{row.job_id}</TableCell>
                <TableCell >{row.user}</TableCell>
                <TableCell >
                    <List sx={{ overflowY: 'auto' }} lg={12}>

                        {rem_date_list.map((nameItem) => (
                            <MenuItem sx={{ height: 30,padding:0,fontSize: '0.875rem' }} key={nameItem} value={nameItem}>
                               {nameItem}
                            </MenuItem>
                        ))}

                    </List>
                </TableCell>
                <TableCell >{row.system}</TableCell>
                <TableCell >{row.object}</TableCell>
                <TableCell >{row.operation}</TableCell>
                <TableCell >{row.frequency}</TableCell>
                {/* <TableCell >{row.start_date}</TableCell>
                <TableCell >{row.end_date}</TableCell> */}
            </TableRow>
        </React.Fragment>
    );
}


export default InnerRow;