import React from 'react';
import {
  AppBar,  
  Box,
  Paper,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';

import InputIcon from '@material-ui/icons/Input';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import theme from '../styles/theme';
import { useNavigate } from 'react-router-dom';

const AuthenticatedNavbar = ({ user }) => {
  const { logout  } = useReactOidc();
  const navigate = useNavigate() 

  
  const handleLogout = () => {
    logout();
    navigate('/'); // landing
  }
  

  return (
    <AppBar
      position='fixed'     
      elevation={0}      
      sx= {{ background:theme.palette.background.default, boxShadow: 'none' }}      
    >
      <Toolbar>
        <Typography 
            variant= 'h5' 
            sx= {{ 
                marginTop: '15px', 
                marginBottom: '9px', 
                color: theme.palette.text.secondary, marginLeft: '220px'
            }}>
            Welcome {user && user.profile.given_name}         
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Paper sx={{ display: { lgDown: 'none', xs: 'block' } }}>          
          <IconButton 
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
        </Paper>
      </Toolbar>
    </AppBar>
  );
};

export default AuthenticatedNavbar;
