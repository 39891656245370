import React, { useEffect } from 'react';
import { AppBar, Toolbar, Box, Paper, Button, Card, Grid } from '@material-ui/core'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Link } from 'react-scroll';
import LoginButton from './LoginButton';




const buttonSX = {
    color: 'white',
    marginRight: '5px',
    "&:hover": {
        backgroundColor: '#00B7F1;'
    },
    "&:active": {
        backgroundColor: '#2A2A86;'
    }
};

const landingFrame = {
    backgroundImage:
        "url('/static/images/bf/bf-landing-frame.png') ",
    height: 'calc(100vh - 100px)',
    backgroundRepeat: "no-repeat",
    backgroundColor:"transparent",
    backgroundSize: "cover",
    boxShadow:'none',
    width: 'inherit'
}


const Navmenu = (props) => {

    const { login } = useReactOidc()
    const pages = ['Access', 'FAQ'];

    useEffect(() => {
        document.title = 'Welcome to CDM | BatchFactory'

    })
    const handleClick = (key, prop) => {
        prop.indexValue(key);
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ height: "70px" }}
                    {...props}><style>{`
            .selected {background-color: #2A2A86!important;}
            
          `}</style>

                    <AppBar
                        position="fixed"
                        elevation={0}
                        sx={{ paddingLeft: '18%', background: 'transparent' }}
                    >
                        <Toolbar>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} >
                                {pages.map((page) => (
                                    <Button
                                        className={(props.indexPage === page) ? 'selected' : ''}
                                        key={page}
                                        sx={buttonSX}
                                        data-testid={page}
                                    >
                                        <Link onClick={() => handleClick(page, props)} key={page} to={page}>{page}</Link>

                                    </Button>))}
                            </Box>
                            <Paper sx={{ display: { lgDown: 'none', xs: 'block' } }}>
                                <LoginButton login={login} />
                            </Paper>
                        </Toolbar>
                    </AppBar>



                </Box>
            </Grid>
            {(props.indexPage === '' && <Grid direction='row' container spacing={1} style={{paddingLeft:'20px', paddingRight:'20px'}}>
                <Grid container item sm={6}>

                </Grid>
                <Grid container item sm={6}>
                    <Card style={landingFrame}>
                    </Card>
                </Grid>
            </Grid>)}

        </Grid>

    );
};
export default Navmenu;