export const contents = [
  {
    title: "Remediation File",
    content: [
      "Format: '.csv' ONLY",
      "Maximum Records: 50K for Sync and 200K overall for all Jobs scheduled for the day (1 file per job per day)",
      "Filename Format: '<jobid>_<yyyymmdd>.csv' , yyyymmdd must match the job schedule"
    ]
  },
  {
    title: "Mandatory Fields",
    content: [
      "Fields: Please check the Remediation Template generator",
      "- DataExtractDate: Valid DateValue - 'YYYY-MM-DD' , MUST be populated for Sync & No Sync record types"
    ],
    minWidth: '360px'
  },
  {
    title: "Job and Record Types",
    content: [
      "Sync: Date validation and Salesforce Update - BAU 2-way Sync",
      "NoSync: Date validation and SF Update - No BAU-2Way Sync",
      "SyncOverwrite: No Date validation - Salesforce Update - BAU 2-way Sync",
      "NoSyncOverwrite: No Date validation - Salesforce Update - No BAU 2-way Sync",
      "Retrigger: No SF Update, ONLY BAU 2-Way Retrigger Sync'",
      "Invalid records: If any of the validation rules fail",
      "Skipped records: If date validation fails during remediation execution"
    ],
    minWidth: '300px'
  },
  {
    title: "File Upload SLA",
    content: [
      "Daily: 9:30PM AEST/DT for same day remediation",
      "Note: Files can be uploaded for any day in the future, except for Adhoc jobs - Must match Job schedule else file will be ignored"
    ]
  }
]