/*********************************BACKEND REQUEST FORMATIONS*****************************************************************/
const REACT_APP_DASHBOARD_COUNT_REQUEST_URL = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/get/counts"
const REACT_APP_DASHBOARD_GRAPH_REQUEST_URL = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/get/graph"
const REACT_APP_GET_PRESIGNED_URL = "https://bcz18wothl.execute-api.ap-southeast-2.amazonaws.com/getpresigneduploadurl/"
const REACT_APP_CREATE_JOB_URL = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/createjob"
const REACT_APP_GET_JOB_URL = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/viewjob"
const REACT_APP_GET_JOB_EXECUTION_URL = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/viewjob"
const REACT_APP_GET_REPORT = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/downloadresult"
const REACT_APP_GET_SCH_JOBS = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/viewthreshold/"
const REACT_APP_GET_REM_FIELDS = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/templatefield/"
const REACT_APP_OPERATIONS = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/operation/"
const REACT_APP_DEL_FILE_URL = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/deletefile"
const REACT_APP_SEARCH_JOB = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/viewresult"
const REACT_APP_SUBMIT_SEARCH = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/search"
const REACT_APP_EDIT_JOB = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/edit"
const REACT_APP_OVERALL_REPORT = "https://7wxoqwbgs7.execute-api.ap-southeast-2.amazonaws.com/dailysummary"
/*******************DASHBOARD - DATA FETCH************************/
export const dashboard_count_request = {
  url: REACT_APP_DASHBOARD_COUNT_REQUEST_URL,
  method: 'POST',
  data: { data: '' }
}

export const dashboard_rem_graph = (data) => {
  return ({
    url: REACT_APP_OVERALL_REPORT,
    method: 'PUT',    
    data: {data}

  })
}

export const dashboard_graph_request = {
  url: REACT_APP_DASHBOARD_GRAPH_REQUEST_URL,
  method: 'POST',
  data: { data: '' }
}

/*******************UPLOAD BUTTON - GETTING SIGNE URL AND FILE UPLOAD TO S3************************/
export const signedurl_request = (name, token) => {
  return ({
    url: REACT_APP_GET_PRESIGNED_URL + name,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const upload_request = (res, file) => {
  return ({
    url: res.data.response.url,
    method: 'PUT',
    data: file,
    headers: {
      'Content-type': 'text/csv'
    }
  })
}

/*******************CREATE JOB - DATA UPLOAD************************/
export const create_request = (data, token) => {

  return ({
    url: REACT_APP_CREATE_JOB_URL,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + `${token}`
    },
    data: { data }
  });
}

/*******************VIEW JOB - DATA LOAD************************/
export const viewjob_request = (data, token) => {
  return ({
    url: REACT_APP_GET_JOB_URL,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}
/*******************DETAILED VIEW OF EACH JOB - DATA FETCH ************************/
export const detailedView_request = (data, token) => {
  return ({
    url: REACT_APP_GET_JOB_EXECUTION_URL,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}
/*******************DOWNLOAD FILE URL - DATA FETCH ************************/
export const downloadURL_request = (data, token) => {
  return ({
    url: REACT_APP_GET_REPORT,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}

/*******************Fetch Sch Jobs ************************/
export const fetch_sch_jobs = (data, token) => {
  return ({
    url: REACT_APP_GET_SCH_JOBS + data.start_date + '/' + data.end_date,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}


/*******************Fetch Fields List ************************/
export const fetch_rem_fields = (data, token) => {
  return ({
    url: REACT_APP_GET_REM_FIELDS + data.system + '/' + data.object + '/' + data.operation,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token
    }

  })
}


/*******************Fetch operations ************************/
export const fetch_operations = (data, token) => {
  return ({
    url: REACT_APP_OPERATIONS + data.system + '/' + data.object,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token
    }

  })
}

/*******************Delete files ************************/
export const deletefile_request = (data, token) => {
  return ({
    url: REACT_APP_DEL_FILE_URL,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}

/*******************VIEW SEARCH - DATA LOAD************************/
export const view_searchjob_request = (data, token) => {
  return ({
    url: REACT_APP_SEARCH_JOB,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}


/*******************VIEW SEARCH DETAILS- DATA LOAD************************/
export const view_searchjob_details = (data, token) => {
  return ({
    url: REACT_APP_SEARCH_JOB,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}



/*******************SUBMIT SEARCH - DATA LOAD************************/
export const submit_search = (data, token) => {
  return ({
    url: REACT_APP_SUBMIT_SEARCH,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}

/*******************Edit Job************************/
export const edit_job = (data, token) => {
  return ({
    url: REACT_APP_EDIT_JOB,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }
  })
}

/*******************Reporting ************************/
export const reporting = (data, token) => {
  return ({
    url: REACT_APP_OVERALL_REPORT,
    method: 'PUT',
    headers: {
      'Authorization': 'Bearer ' + token
    },
    data: { data }

  })
}
