import React from 'react';
import {
  Container, Box, TextField, Grid, Backdrop, CircularProgress, Button,
  Typography

} from '@material-ui/core';

import * as Options from '../common/requestFormat';
import axios from 'axios';
import { decryptData } from '../../utils/cipherhelper';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useState } from 'react';
import StatusMessage from '../../components/common/statusMessage.js';
import * as Constants from '../createjob/constants'
import MultipleSelectCheckmarks from './multiselectlist'
import { CSVLink } from 'react-csv';
import { fetch_operations } from '../common/requestFormat';



const GenerateTemplate = () => {
  const [header, setHeader] = useState([]);
  const intialPayload = {
    system: "Salesforce",
    object: "Account",
    operation: ""
  }
  const [payload, setPayload] = useState(intialPayload);
  const initialJobs = [];
  const [fieldList, setFieldList] = useState(initialJobs);
  const token = decryptData(sessionStorage.getItem('oidcString')).access_token;
  const [operations, setOperations] = useState([]);
  const [errorOperation, setErrorOperation] = useState(false);

  const {
    isFetching,
    error,
    data } = useQuery(
      ['projects', payload, token], () => fetchFieldList(payload, token),
      { keepPreviousData: true, refetchOnWindowFocus: false }
    )

  useEffect(() => {
    setFieldList(data?.body);
  }, [data])


  const fetchFieldList = async (filter, token) => {
    if (filter.operation === "") {
      operationsAxios(filter);
    } else {
      const { data } = await axios(Options.fetch_rem_fields(filter, token));
      return data;
    }

  }

  const handleFildsSelection = (fields, value) => {
    const index = fieldList.findIndex(f => f.name === value);
    const newArray1 = [...fieldList];
    newArray1[index] = { ...fieldList[index], mandatory: !fieldList[index].mandatory };
    setFieldList(newArray1);

  }
  //when we change object dropdown
  const fetchOper = (object) => {
    let input = {
      system: '',
      object: ''
    }
    input.system = payload.system;
    input.object = object
    operationsAxios(input)
  }
  //axios call for fetching operations
  const operationsAxios = (input) => {
    axios(fetch_operations(input, token))
      .then((result) => {
        if (result.data) {
          let operations = result.data.body;
          setPayload(prevState => ({
            ...prevState,
            system: prevState.system,
            object: input.object,
            operation: operations[0].value
          }));
          setOperations(operations)
          // setOperations(temp)
        }
      })
      .catch(() => {
        setErrorOperation(true)
      });
  }


  const handleoperationChange = (keyvalue) => {
    setPayload(prevState => ({
      ...prevState,
      system: prevState.system,
      object: prevState.object,
      operation: keyvalue.currentTarget.value
    }));
  }
  const handleobjectChange = (keyvalue) => {
    fetchOper(keyvalue.currentTarget.value);
  }

  useEffect(() => {
    if (fieldList?.length > 0) {
      const mandated_fields = fieldList.filter(item => item.mandatory).map(item => (item.name))
      setHeader(mandated_fields);
    }
  }, [fieldList])


  if (error || errorOperation) return (<StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." />)

  // if (errorOperation) return (<StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." />)

  return (<Container maxWidth={false}
    sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      height: 'flex',
      alignContent: 'flex-start',
      spacing: 2,
      paddingRight: '10px!important',
      paddingLeft: '15px!important'

    }}
  ><Backdrop open={isFetching}>
      <CircularProgress color="inherit" />
    </Backdrop>
    <Box sx={{ flexGrow: 1 }}>

      <Grid container direction='row' spacing={2}>
        <Grid item lg={4}>
          <Grid item lg={12}>
            <TextField
              id="system"
              label="System"
              value={payload.system}
              sx={{ width: '100%' }}
              fullWidth
              required
              disabled
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              label="Select Object"
              name="object"
              margin="normal"
              value={payload.object}
              onChange={handleobjectChange}
              required
              sx={{ width: '100%' }}
              select
              SelectProps={{ native: true }}
              fullWidth
            >
              {Constants.objects.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={12}>
            {/* {operations?.length === 0 && (<StatusMessage severity="error" message="Sorry, No Operations available." />)} */}
            {operations?.length > 0 && (<TextField
              label="Select Operation"
              name="operation"
              margin="normal"
              value={payload.operation}
              onChange={handleoperationChange}
              required
              select
              sx={{ width: '100%' }}
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            >
              {operations.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>)}
          </Grid>
          <Grid item lg={12}>
            {(fieldList?.length > 0 && header.length >0) && (<Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              sx={{
                width: '100%', marginLeft: '10px',
                background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
              }}
            >
              <CSVLink
                filename="jobid_yyyymmdd.csv"
                headers={header}
                data={[]}
                className="btn btn-primary  add-group-btn"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                Export Remediation Template
              </CSVLink>
            </Button>)}
          </Grid>
        </Grid>
        <Grid item lg={8}>
          <Grid item lg={12}>
            <Typography sx={{ pl: 5, pt: 2,display:'inline' }}>Select Fields</Typography>

            <Typography sx={{ pl:1,fontSize:'small',color:'gray', fontStyle:'italic',display:'inline' }}>Pre-Selected Fields are Mandatory</Typography>
            {/* {fieldList?.length === 0 && (<StatusMessage severity="error" message="Sorry, No Fields available." />)} */}
            <MultipleSelectCheckmarks data={fieldList} updateFields={handleFildsSelection} />




          </Grid>
        </Grid>
      </Grid>

    </Box>
    <Box sx={{ py: 2, ml: 2 }}>




    </Box>

  </Container>
  )
}

export default GenerateTemplate;
