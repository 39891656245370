import React from 'react';
import {
    Stack,
    Avatar,
    Card,
    CardHeader
} from '@material-ui/core';
import theme from '../styles/theme';
import { decryptData } from '../utils/cipherhelper';
//import { useReactOidc } from '@axa-fr/react-oidc-context';

function SidebarHeader() {
    const oidcUser = decryptData(sessionStorage.getItem('oidcString'));

    return (
        <Stack
            spacing={0}
            justifyContent='center'
            color={theme.palette.background.default}
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}
            
        >
            <Card 
                sx = {{
                    border: 0,
                    color: 'white',
                    padding: '0px',
                    backgroundColor: 'transparent'
                }}
            >
            <CardHeader
                avatar={<Avatar alt='bf' src='/static/images/batchfactory.png' />}
                title='batchfactory'
            />
            </Card>
            <Card 
                sx = {{
                    border: 0,
                    color: 'white',
                    backgroundColor: 'transparent',
                }}
            >
            <CardHeader
                sx={{fontSize: '1em', alignSelf: 'left'}}
                avatar={<Avatar alt={oidcUser && oidcUser.given_name} src='/broken.png' />}
                title={oidcUser && `${oidcUser.profile.given_name} ${oidcUser.profile.family_name}`}
            />
            </Card>
        </Stack>
    );
}

export default SidebarHeader;


