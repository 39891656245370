import React from 'react';

import {
  Table, TableBody, TableCell, TableHead,
  TableContainer, TableRow, Paper, 


} from '@material-ui/core';
import Row from './Jobdetailsrow';

export default function CollapsibleJobs(props) {
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Record ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.jobs && props.jobs.length > 0 && props.jobs.map((job) => (

            <Row key={job.record_id} row={job} />


          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

