import React from 'react';
import {
    Container, Box, TextField, Grid, Backdrop, CircularProgress,
    Accordion, Typography, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import ScheduledGraph from './scheduledgraph';
import ScheduledTable from './scheduletable';
import * as Options from '../common/requestFormat';
import axios from 'axios';
import { decryptData } from '../../utils/cipherhelper';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useState } from 'react';
import StatusMessage from '../../components/common/statusMessage.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const fetchJobList = async (filter, token) => {
    const { data } = await axios(Options.fetch_sch_jobs(filter, token));
    return data;
}

const defaultEndDate = (keyvalue, days) => {
    let selDate = new Date(keyvalue);
    selDate.setDate(selDate.getDate() + days)
    return selDate.toISOString().slice(0, 10);
}

const ScheduleJob = () => {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const currentDate = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);
   // const currentDate = new Date().toISOString().slice(0, 10);
    const [edate, setEdate] = useState(defaultEndDate(currentDate, 14))

    const intialPayload = {
        "start_date": currentDate,
        "end_date": defaultEndDate(currentDate, 7)
    }
    const [payload, setPayload] = useState(intialPayload);
    const initialJobs = [];
    const [schJobs, setSchJobs] = useState(initialJobs);
    const token = decryptData(sessionStorage.getItem('oidcString')).access_token;
    const user = decryptData(sessionStorage.getItem('oidcString'));

    const {
        isFetching,        
        error,
        data,
        refetch } = useQuery(
            ['projects', payload, token], () => fetchJobList(payload, token),
            { keepPreviousData: true, refetchOnWindowFocus: false }
        )

    useEffect(() => {

        setSchJobs(data?.body);
    }, [data])

    const handleedChange = (keyvalue) => {
        setPayload(prevState => ({
            ...prevState,
            start_date: prevState.start_date,
            end_date: keyvalue.currentTarget.value
        }));
    }
    const handlesdChange = (keyvalue) => {
        setPayload(prevState => ({
            ...prevState,
            start_date: keyvalue.currentTarget.value,
            end_date: defaultEndDate(keyvalue.currentTarget.value, 7)
        }));

    }   

    const handleFileDelete = () => {
        refetch();        
    }
    useEffect(() => {
        setEdate(defaultEndDate(payload.start_date, 14));
    }, [payload])
    if (error) return (<StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." />)
    if (!isFetching && data.body.length === 0) return (<StatusMessage severity="error" message="Sorry, No Scheduled Jobs Found." />)
    return (<Container maxWidth={false}
        sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            height: 'flex',
            alignContent: 'flex-start',
            spacing: 2,
            paddingRight: '10px!important',
            paddingLeft: '15px!important'

        }}
    ><Backdrop open={isFetching}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ flexGrow: 1 }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                ><Box sx={{ display: 'flex', width: '100%' }}>
                        <Typography variant="h6" sx={{ marginRight: 'auto' }}>Select Filter</Typography>
                        <Typography variant="h6"> Capacity : Sync - 50K, Overall - 200K</Typography>
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction='row' spacing={2}>
                        <Grid item lg={6}>
                            <TextField
                                id="startdate"
                                label="Start Date"
                                type="date"
                                variant="outlined"
                                margin="normal"
                                onChange={handlesdChange}
                                required
                                value={payload.start_date}
                                sx={{ width: '100%' }}
                                data-testid='sch-sd'
                                inputProps={{
                                    min: currentDate
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <TextField
                                id="enddate"
                                label="End Date"
                                type="date"
                                margin="normal"
                                sx={{ width: '100%' }}
                                value={payload.end_date}
                                required
                                onChange={handleedChange}
                                data-testid='sch-ed'
                                inputProps={{
                                    min: payload.start_date,
                                    max: edate
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
        {schJobs?.length > 0 && (<Box sx={{ flexGrow: 1 }}>
            <ScheduledGraph graphData={schJobs} />
            <ScheduledTable user={user} jobs={schJobs} delete={handleFileDelete} />
        </Box>)}

    </Container>
    )
}

export default ScheduleJob;
