import React, { useState, useEffect } from 'react';
import {
    TextField, Grid, Tooltip, IconButton
} from '@material-ui/core';
import * as Constants from '../createjob/constants';
import { blue } from '@material-ui/core/colors';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const Filter = (props) => {

    const [filterArray, setFilterArray] = useState([]);//filterkey select

    const [filteredDates, setFilterDates] = useState([]);
    const [selectFilterAttr, setSelectFilterAttr] = useState('N');

    var months = [
        "", "January", "February", "March", "April", "May",
        "June", "July", "August", "September", "October",
        "November", "December"
    ];

    const [filterPayload, setFilterPayload] = useState('');

    const filterType = Constants.filterList; //filterattribute list

    const fetchFilterValues = () => {
        if (props.jobs) {
            return [...new Set(props.jobs.map(item => item[selectFilterAttr]))]
        }
    }
    const handleChange = (keyvalue) => {
        setSelectFilterAttr(keyvalue.currentTarget.value);
        //setFilterPayload(undefined);
    }

    const formUniqueDateArray = () => {
        let dates = fetchFilterValues();
        let formattedDateArray = dates.map(item => {
            let element = item.substr(0, 7);
            let format = months[parseInt(element.substr(5, 6))] + " " + element.substr(2, 2);
            let dateobj = {
                label: format,
                value: element
            };
            return dateobj;
        })
        let jsonObject = formattedDateArray.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        setFilterDates(uniqueArray);
    }
    useEffect(() => {
        if (selectFilterAttr === 'N') {
            let filterObjNew = {
                "filter_attribute": "N",
                "filter_key": "N"
            }
            props.filter(filterObjNew);
        } else if (selectFilterAttr === 'createddate') {
            formUniqueDateArray();
        } else {
            let selectData = fetchFilterValues();
            setFilterArray(selectData);

        }
    }, [selectFilterAttr])

    useEffect(() => {
        if (filterArray) {
            setFilterPayload(filterArray[0]);
            let filtered = props.jobs.filter(item => item[selectFilterAttr] === filterArray[0])
            props.filteredList(filtered);
        }
    }, [filterArray])

    const filterByDate = (keyValue) => {
        setFilterPayload(keyValue.currentTarget.value)
        let filtered = props.jobs.filter(item => item[selectFilterAttr].includes(keyValue.currentTarget.value));
        props.filteredList(filtered);
    }

    const selectFilter = (keyValue) => {
        setFilterPayload(keyValue.currentTarget.value)
        let filtered = props.jobs.filter(item => item[selectFilterAttr] === keyValue.currentTarget.value)
        props.filteredList(filtered);
    }
    useEffect(() => {
        setSelectFilterAttr('N');

    }, [props.jobs])

    const resetFilter = () => {
        setSelectFilterAttr("N");
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={1}>
                {(selectFilterAttr !== 'N') && <Tooltip data-testid="resetFilter" title="Reset Filters" sx={{ marginTop: '20px' }} onClick={resetFilter}>
                    <IconButton>
                        <HighlightOffIcon sx={{ cursor: 'pointer' }} style={{ color: blue[300] }} />
                    </IconButton>
                </Tooltip>}

            </Grid>
            <Grid item md={5}>
                <TextField sx={{ width: '100%' }}
                    label="Select Filter"
                    name="object"
                    margin="normal"
                    value={selectFilterAttr}
                    onChange={handleChange}
                    required
                    select
                    inputProps={{ 'data-testid': 'filterSel' }}
                    SelectProps={{ native: true }}
                    fullWidth
                    data-testid='filterSelect'
                >
                    {filterType.map((option) => (
                        <option data-testid="select-option"
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </TextField>

            </Grid>
            <Grid item md={6}>
                {(selectFilterAttr !== 'N' && selectFilterAttr !== 'createddate') && <TextField sx={{ width: '100%' }}
                    label="Select Value"
                    name="object"
                    margin="normal"
                    value={filterPayload}
                    onChange={selectFilter}
                    required
                    select
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{ native: true }}
                    fullWidth
                >
                    {filterArray.map((option) => (
                        <option
                            key={option}
                            value={option}
                        >
                            {option}
                        </option>
                    ))}
                </TextField>}
                {(selectFilterAttr === 'createddate') &&
                    <TextField sx={{ width: '100%' }}
                        label="Select Value"
                        name="object"
                        margin="normal"
                        value={filterPayload}
                        onChange={filterByDate}
                        required
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        SelectProps={{ native: true }}
                        fullWidth
                    >
                        {filteredDates.map((option) => (
                            <option
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </TextField>}


            </Grid>
        </Grid >
    )
}

export default Filter;
