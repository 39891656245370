import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import AuthenticatedNavbar from './AuthenticatedNavbar';
import AuthenticatedSidebar from './AuthenticatedSidebar';
import AuthenticatedContent from './AuthenticatedContent';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { encryptData, decryptData } from '../utils/cipherhelper';
import theme from '../styles/theme';

function AuthenticatedApp() {
    const { oidcUser } = useReactOidc();
    const navigate = useNavigate();
    let userStore = ''
    if (oidcUser) {
        sessionStorage.setItem('oidcString', encryptData(oidcUser));
    }

    if (sessionStorage.getItem('oidcString')) {
        userStore = decryptData(sessionStorage.getItem('oidcString'));
    } else {
        navigate('/');
    }

    useEffect(() => {
        document.title = 'CDM | BatchFactory'
    })

    return (
        <ThemeProvider theme={theme}>
            {
                oidcUser || userStore
                    ?
                    <Grid container spacing={2}>
                        <AuthenticatedNavbar user={userStore} />
                        <AuthenticatedSidebar />
                        <AuthenticatedContent />
                    </Grid>
                    :
                    navigate('/')
            }
        </ThemeProvider>
    );
}

export default AuthenticatedApp;