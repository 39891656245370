import React from 'react';
import { Box } from '@material-ui/core';
import {
    BarChart,
    Bar,
    XAxis,
    ResponsiveContainer,
    Tooltip, 
    Legend,
    LabelList

} from "recharts";


const UserGraph = (props) => {    

    return (<Box
        sx={{
            borderRadius: 1.5,
            backgroundColor: '#fff',
            padding: '5px',
            maxWidth: '100%'
        }}><ResponsiveContainer width="100%" height={250}>
        <BarChart
                        
                        width={800}
                        height={250}
                        data={props.graphData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20
                        }}
                    >
                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                        <XAxis  style={{fontSize:'10px'}}  angle={0} dataKey="user" type="category" scale="band" />
                        {/* <YAxis type="number" /> */}
                        <Tooltip />
                        <Legend   wrapperStyle={{bottom:5}}/>
            
                        <Bar dataKey="remediated" barSize={20}  stackId="a" fill="green" >
                            <LabelList dataKey="remediated_display_value" style={{fontSize:'10px'}}  position="top" />
                        </Bar>
                        
                        
            
                    </BarChart>
     </ResponsiveContainer>


    </Box>)
}

export default UserGraph;