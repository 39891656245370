import React, { useState } from 'react';
import axios from 'axios';
import { downloadURL_request } from '../common/requestFormat';
import StatusMessage from '../../components/common/statusMessage.js';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { green } from '@material-ui/core/colors';
//import { useReactOidc } from '@axa-fr/react-oidc-context';
import {
    Button
} from '@material-ui/core';
import {decryptData} from '../../utils/cipherhelper';

const Download = (props) => {
    const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
    const token = oidcUser?.access_token;    
    const [downloadFailure, setDownloadFailure] = useState(false);

    const downloadClick = () => {
        const values = {
            "bucket": props.item.bucket,
            "key": props.item.key,
            "expire_time": 3000
        }
        axios(downloadURL_request(values, token))
            .then((result) => {
                if (result.data.status === 204) {
                    setDownloadFailure(true);
                } else {
                    let url = result.data.body;
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDownloadFailure(false);
                }


            })
            .catch(() => {
                setDownloadFailure(true);
            });

    }

    return (
        <>
            <Button data-testid='btn-download' onClick={() => { downloadClick() }}><CloudDownloadIcon style={{ color: green[300] }} /></Button>
            {(downloadFailure) && (<StatusMessage severity="error" message="Sorry,Invalid Request" />)}
        </>
    )
}

export default Download;