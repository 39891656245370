import * as React from 'react';
import { useNavigate } from 'react-router-dom';
const Auth = () => {
  const navigate = useNavigate()
  return (
    <div>
      <h3>Loading...</h3>
      {navigate('./app/dashboard')}   
    </div>
  )
};

export default Auth;