import React from 'react';
import Alert from '@material-ui/core/Alert';


const StatusMessage = ({severity, message, margintop, marginleft, minwidth }) => {
    return(
        <Alert 
            variant='filled' 
            severity={severity} 
            sx={{ 
                marginTop: margintop || 0, 
                marginLeft: marginleft || 0,
                width: minwidth || '97%'
            }}>
                {message}
        </Alert>
    )
}

export default StatusMessage;
