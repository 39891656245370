import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Box,
    Backdrop,
    Card,
    Divider

} from '@material-ui/core';

import ShowJobTable from './ShowJobTable.js';
import StatusMessage from '../components/common/statusMessage.js';
import { decryptData } from '../utils/cipherhelper.js';

const allJobsCheck = (alljobs) => {
    return alljobs == 1 ? true : false;
}

const ShowJob = (props) => {
    const [allJobs] = useState(allJobsCheck(props.index));
    const user = decryptData(sessionStorage.getItem('oidcString'));   
    const handleSort = (property, sort) => {
        props.sortData(property, sort);
    }

    if (props.error) return (<StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." />)

    return (
        <Box sx={{ mt: 3, flexGrow: 1 }}>
            <Card >
                <Backdrop open={props?.isfetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Divider />
                {(!props.isfetching && <PerfectScrollbar>
                    <Box sx={{overflowX:'scroll'}}>
                        {(props.jobs?.length === 0) && (<StatusMessage severity="error" message="Sorry, No Jobs found" />)}
                        {(props.jobs?.length>0 && <ShowJobTable user={user} allJobs={allJobs} index={props.index} filter={props.filter} jobs={props.jobs} sortHandler={handleSort} />)}

                    </Box>
                </PerfectScrollbar>)}
            </Card>
        </Box>
    )
};

export default ShowJob;