import React from 'react';
import { Box } from '@material-ui/core';
import {
    Table,
    TableBody,
    TableCell,

    TableRow,

} from '@material-ui/core';
import FileList from './fileList';

const ScheduledTable = (props) => {    
    const keys = Object.keys(props.jobs[0]);

    return (<Box
        sx={{
            borderRadius: 1.5,
            backgroundColor: '#fff',
            padding: '5px',
            maxWidth: '100%'
        }}>
        <Table size="small">
            <TableBody>
                {(keys).map((job, index) => (<TableRow key={job} style={{ height: 10 }} sx={{ '&:first-of-type td': { fontWeight: 'bold', borderTop: '1px solid lightgray' } }}>
                    <TableCell sx={{verticalAlign:'top'}}>
                        {job.toUpperCase()}
                    </TableCell>
                    {(props.jobs.map((jobItem) => (<TableCell key={jobItem.date} sx={{verticalAlign:'top'}}>
                        {(index < keys.length - 1) && jobItem[job]}
                        {(index == keys.length - 1) && <FileList user={props.user} data={jobItem} delete={props.delete}/>}
                    </TableCell>)))}
                </TableRow>))}
            </TableBody>
        </Table>

    </Box>)
}

export default ScheduledTable;
