import * as React from 'react';
import AccessMenu from './AccessMenu';
import FAQMenu from './FAQMenu';
import { Box } from '@material-ui/core'

const Navmenucontent = (props) => {

    return (
        <>
        {(props.indexPage !== '') && (<Box
                id="Access"
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop='5%'
                marginLeft='10%'
                marginRight='10%'
                backgroundColor='rgb(0,30,60)!important'
                color='white'
                paddingLeft='15px'
                borderRadius='10px'
                minHeight='50%'

            >
            {(props.indexPage === 'Access') && (<AccessMenu /> )}
            {(props.indexPage === 'FAQ') && (<FAQMenu /> )}
        </Box>)}
        </>
    )
};

export default Navmenucontent;