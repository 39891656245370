import * as React from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer, Link
} from '@material-ui/core'

const AccessMenu = () => {

    return (
        <TableContainer sx={{ maxHeight: 440 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }}>Steps</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow key="1"

                    ><TableCell sx={{ color: 'white' }}>
                          Navigate to   <Link href="https://ags.corp.telstra.com/aveksa/main">https://ags.corp.telstra.com/aveksa/main</Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }} >
                            Manage Access - Access Request (dropdown) - Request Access for yourself or others<br />
                            Search for BatchFactory - Click on the right Application for Prod
                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }} >
                        CDM BatchFactory                       

                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }} >
                        In User Selection view, Search for your dNumber and Select Request access for relevant Role below
                        please note Restricted roles are reserved and require prior approval.
                        </TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'white', border: 0 }} >
                        CDM Team should apply for CDMUser role which provides all access required for remediation
                        CDMAdmin role is restricted for CDM users who perform administration activities<br/>
                        Prod User - BF-CDMUSER CDM                       

                        </TableCell>

                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>



    )
};

export default AccessMenu;