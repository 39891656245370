import axios from 'axios';
let counter = 0;
axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        const originalRequest = err.config      
        
        if (err.response.status === 401) {
            if (counter<8) {
                counter++;
                return axios(originalRequest);
                // alert('Session Token Invalid')          
                // window.location.assign('/')
                // return;
            } else {
                alert('Session Expired');
                window.location.assign('/')
                return;
            }
        }
        return Promise.reject(err);
    }
);
