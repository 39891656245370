
import {
    Edit as CreateIcon,
    BarChart as BarChartIcon,
    Settings as SettingsIcon,
   // Bookmark as ShoppingBagIcon,
    File ,
    Search,
    Eye,
    Home,
    Monitor
} from 'react-feather';

const SidebarItems = [
    {
        href: '/app/dashboard',
        icon: Home,
        title: 'Dashboard'
    },
    {
        href: '/app/createjob',
        icon: CreateIcon,
        title: 'Create Job'
    },
    {
        href: '/app/viewjob',
        icon: Eye,
        title: 'View Job'
    },
   
    {
        href: '/app/schedulejob',
        icon: Monitor,
        title: 'Capacity'
    },
    {
        href: '/app/reporting',
        icon: BarChartIcon,
        title: 'Reporting'
    },
    {
        href: '/app/searchjob',
        icon: Search,
        title: 'Search'
    },
    {
        href: '/app/generateTemplate',
        icon: File,
        title: 'Template'
    },
    {
        href: '/app/settings',
        icon: SettingsIcon,
        title: 'Configuration'
    }
];

export default SidebarItems;
