import React from 'react';
import { IconButton } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const LoginButton = ( { login } ) => {

    return (
        <IconButton
            data-testid='btn-login'
            color="inherit"
            onClick={login}
        >
            <ExitToAppIcon color='#' />
        </IconButton>
    )
}

export default LoginButton;