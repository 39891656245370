import React, { Suspense } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { decryptData } from '../utils/cipherhelper';

export default function Dashboard() {
  const { oidcUser } = useReactOidc();
  let token = (oidcUser)?oidcUser?.access_token:decryptData(sessionStorage.getItem('oidcString')).access_token;
  let user = decryptData(sessionStorage.getItem('oidcString')).profile.sub;
  const RemGraph = React.lazy(() => import('../components/dashboard/remGraph'));
  const JobCard = React.lazy(() => import('../components/dashboard/jobCard'))
  const JobGraph = React.lazy(() => import('../components/dashboard/JobGraph'))

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container  sx={{width:'100%'}} spacing={2} >
        <Suspense fallback={<div>Loading Summary...</div>}>
          <JobCard token={token} />
        </Suspense>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={7}>
          <Suspense fallback={<div>Loading Graph...</div>}>
            <JobGraph token={token} />
          </Suspense>
        </Grid>
        <Grid item md={5}>
          <Suspense fallback={<div>Config Loading...</div>}>
          <RemGraph token={token} user={user}/>
          </Suspense>
        </Grid>
      </Grid>
    </Box>
  );

}
