import React ,{useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Link,
    Container,
    
    TablePagination

} from '@material-ui/core';


import StatusMessage from '../../components/common/statusMessage.js';
const SearchTable = (props) => {
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    //const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event,newPage) => {
        setPage(newPage);
    };


    function handleClick(job) {
        const path = '/app/searchdetail/' + job.search_id;
        navigate(path, { replace: false });
    }
    if (!props.isFetching && props.jobs.length === 0) return (<StatusMessage severity="error" message="Sorry, No Search Results Found." />)

    return (
        <Container maxWidth={false}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Search Id
                        </TableCell>
                        <TableCell>
                            Status
                            {/* <Button data-testid='btn-refesh' onClick={() => { refresh() }}>
                                <RefreshIcon style={{ color: green[300] }} /></Button> */}

                        </TableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.jobs.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((job) => (
                        <TableRow
                            hover
                            key={job.search_id}
                        >
                            {job.status === "Completed" && (<TableCell>
                                <Link href="#" data-testid='search_job_link' underline="hover" onClick={() => handleClick(job)}>
                                    {job.search_id}
                                </Link>
                            </TableCell>)}
                            {job.status === "In Progress" && (<TableCell>
                                {job.search_id}
                            </TableCell>)}
                            <TableCell>
                                {job.status}
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={props.jobs.length}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10]}
                onPageChange={handleChangePage}
            />
        </Container>
    )
};

export default SearchTable;