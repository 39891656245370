export const systems = [
  {
    value: 'Salesforce',
    label: 'Salesforce'
  }
];

export const objects = [
  {
    value: 'Account',
    label: 'Account'
  },
  {
    value: 'Contact',
    label: 'Contact'
  },
  {
    value: 'AccountContactRelation',
    label: 'AccountContactRelation'
  },
  {
    value: 'Premises',
    label: 'Premises'
  },
  {
    value: 'User',
    label: 'User'
  },
  {
    value: 'TPA-User',
    label: 'TPA-User'
  },
  {
    value: 'Asset',
    label: 'Asset'

  },
  {
    value: 'ContactHistory',
    label: 'ContactHistory'
  },
  {
    value: 'OrderItem',
    label: 'OrderItem'
  }
];

export const operations = [
  {
    value: 'Sync',
    label: 'Sync'
  },
  {
    value: 'SyncOverwrite',
    label: 'SyncOverwrite'
  }, {
    value: 'Nosync',
    label: 'Nosync'
  }, {
    value: 'NosyncOverwrite',
    label: 'NosyncOverwrite'
  },
  {
    value: 'Retrigger',
    label: 'Retrigger'
  },

  {
    value: 'Create',
    label: 'Create'
  },
  {
    value: 'Delete',
    label: 'Delete'
  }
];

export const frequencies = [
  
  {
    value: 'Daily',
    label: 'Daily'
  },
  {
    value: 'Weekly',
    label: 'Weekly'
  },
  {
    value: 'Monthly',
    label: 'Monthly'
  }
];

export const Adminfrequencies = [
  {
    value: 'Adhoc',
    label: 'Adhoc'
  },
  {
    value: 'Daily',
    label: 'Daily'
  },
  {
    value: 'Weekly',
    label: 'Weekly'
  },
  {
    value: 'Monthly',
    label: 'Monthly'
  }
];

export const days = [
  {
    value: '1',
    label: 'Monday'
  },
  {
    value: '2',
    label: 'Tuesday'
  },
  {
    value: '3',
    label: 'Wednesday'
  },
  {
    value: '4',
    label: 'Thursday'
  },
  {
    value: '5',
    label: 'Friday'
  },
  {
    value: '6',
    label: 'Saturday'
  },
  {
    value: '7',
    label: 'Sunday'
  }
];

export const dates = [
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '4',
    label: '4'
  },
  {
    value: '5',
    label: '5'
  },
  {
    value: '6',
    label: '6'
  },
  {
    value: '7',
    label: '7'
  },
  {
    value: '8',
    label: '8'
  },
  {
    value: '9',
    label: '9'
  },
  {
    value: '10',
    label: '10'
  },
  {
    value: '11',
    label: '11'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '13',
    label: '13'
  },
  {
    value: '14',
    label: '14'
  },
  {
    value: '15',
    label: '15'
  },
  {
    value: '16',
    label: '16'
  },
  {
    value: '17',
    label: '17'
  },
  {
    value: '18',
    label: '18'
  },
  {
    value: '19',
    label: '19'
  },
  {
    value: '20',
    label: '20'
  },
  {
    value: '21',
    label: '21'
  },
  {
    value: '22',
    label: '22'
  },
  {
    value: '23',
    label: '23'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '25',
    label: '25'
  },
  {
    value: '26',
    label: '26'
  },
  {
    value: '27',
    label: '27'
  },
  {
    value: '28',
    label: '28'
  },
  {
    value: '29',
    label: '29'
  },
  {
    value: '30',
    label: '30'
  },
  {
    value: '31',
    label: '31'
  }
];
export const filterList = [
  {
    value: 'N',
    label: 'None'
  },
  {
    value: 'object',
    label: 'Object'
  },
  {
    value: 'frequency',
    label: 'Frequency'
  },
  {
    value: 'issueid',
    label: 'IssueID'
  },
  {
    value: 'username',
    label: 'User'
  },
  {
    value: 'operation',
    label: 'Job Type'
  },
  {
    value: 'createddate',
    label: 'Created Date'
  }
]
