import React, { useState } from 'react';
import Navmenu from './Navmenu';
 import Navmenucontent from './Navmenucontent';

const sectionStyle = {
  backgroundImage:
    "url('/static/images/bf/bf-background.png') ",
  height: "96vh",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding:10
};


const Home = () => {
  sessionStorage.removeItem('oidcString');
  function currentIndexPPage(value) {    
    setIndexPage(value);
  }
  const [indexPage, setIndexPage] = useState('');
  return (
    <div style={sectionStyle}>
      <Navmenu indexPage={indexPage} indexValue={currentIndexPPage} />
      <Navmenucontent indexPage={indexPage} />
    </div>
  );
};
export default Home;