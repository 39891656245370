import { createTheme } from '@material-ui/core';

const shadows = [
  'none',
  '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
  '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)'
];

const theme = createTheme({
  palette: {
    common: {
      black:"rgba(0, 0, 0, 1)","white":"#fff"
    },
    background: {
      paper:"rgba(255, 255, 255, 1)",
      default:"#fafafa"
    },
    primary: {
      light:"#6746c3",
      main:"#311b92",
      dark:"#000063",
      contrastText:"#fff"
    },
    secondary: {
      light:"#ff6434",
      main:"#dd2c00",
      dark:"#a30000 ",
      contrastText:"#fff"
    },
    error: {
      light:"rgba(250, 147, 147, 1)",
      main:"rgba(250, 8, 0, 1)",
      dark:"rgba(179, 9, 9, 1)",
      contrastText:"#fff"
    },
    text: {
      primary:"rgba(0, 0, 0, 1)",
      secondary:"rgba(0, 0, 0, 0.54)",
      disabled:"rgba(0, 0, 0, 0.38)",
      hint:"rgba(0, 0, 0, 0.38)"
    }
  },
  shadows,
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiDrawer: {
      defaultProps: {        
          elevation: 12
      },
      styleOverrides: {
        root:{
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
        },
        paper: {
          margin: '5px',
          width: '220px',
          background: 'linear-gradient(105deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 45%, rgba(0,212,255,1) 90%)',
          borderRadius: '9px',
          height: '96%',
          square: false,
          boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
        }
      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: '#f6f6f6',
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          marginLeft: 15,
          marginTop: 15,
          borderRadius: 9,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        label: {
          fontSize: '1.2em',
          color: '#d3d3d3',
          '&:hover': {
            fontSize: '1.3em',
            color: '#fff' 
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '20px',
          height: '60px',
          color: '#FFF',
          '&:hover': {
            backgroundColor: '#000063',
            borderRadius: 12,
          }
        } 
      }
    },
    MuiCardHeader:{
      styleOverrides: {
        title: {
          fontSize: '1.0rem'
        },
        subheader: {
          fontSize: '1.3rem'
        },
        content: {
          padding: '9px',
        },
        avatar: {
          margin: 0,
        },
        root: {
          padding: 0,
        }

      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          justifyContent: 'center',
        },
        label: {
          color: '#e6e6e6',
          fontSize: '1rem'
        },
        avatar: {
          color: '#fff', 
          backgroundColor: '#6746c3', 
          width: '60px', 
          height: '60px'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: 6, 
          borderWidth: '0.3px',
          borderColor: '#d3d3d3'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '48%',
          margin: '10px'
        }
      }
    }
  }
});

export default theme;
