import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthenticationProvider, oidcLog, InMemoryWebStorage } from '@axa-fr/react-oidc-context';
import './App.css';
import authConfig from './utils/AuthConfig';
import routes from './routes';
import Auth from './views/Auth';
import NotAuthenticated from './components/auth/NotAuthenticated';
import './utils/responseInterceptor';

const App = () => {
  const routing = useRoutes(routes);

  return (
          <AuthenticationProvider 
            configuration={authConfig}
            loggerLevel={oidcLog.ERROR}
              isEnabled={true}
              callbackComponentOverride={Auth}
              notAuthenticated={NotAuthenticated}      
              UserStore={InMemoryWebStorage}
          >
            {routing}
          </AuthenticationProvider>
      
)};

export default App;
