import React from 'react';
import {
    Container, Box, TextField, Grid, Backdrop, CircularProgress, Button,
    Accordion, Typography, AccordionSummary, AccordionDetails, Card, Tooltip
} from '@material-ui/core';


import axios from 'axios';
import { decryptData } from '../../utils/cipherhelper';
import { useEffect } from 'react';
import { useState } from 'react';
import StatusMessage from '../../components/common/statusMessage.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { view_searchjob_request, submit_search } from '../common/requestFormat';
import SearchTable from './searchtable';
import RefreshIcon from '@material-ui/icons/Refresh';
import { green } from '@material-ui/core/colors';

const generateRecordID = (userid) => {
    const jobdate = new Date().getTime().toString()
    return userid + '-search-' + jobdate.substring(jobdate.length - 6, jobdate.length)
}

const validateInput = (str) => {
    const isBelowThreshold = (currentValue) => currentValue.length == 18;
    if (str.indexOf(',') >= 0) {
        return str.split(',').every(isBelowThreshold);
    } else {
        return str.length === 18;
    }

}

const SearchJob = () => {

    const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
    const token = oidcUser.access_token;
    const user = oidcUser?.profile.sub;
    const intialPayload = {
        "user_id": user
    }
    const [payload] = useState(intialPayload);
    const [values, setValues] = useState('')
    const initialJobs = [];
    const [jobs, setJobs] = useState(initialJobs);

    const [error, setError] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [createError, setCreateError] = useState(false);
    const [isFetching, setIsfetching] = useState(true);


    useEffect(() => {
        setIsfetching(true)
        searchAxios(payload);
    }, [])


    //axios call for fetching search results
    const searchAxios = (input) => {
        setIsfetching(true);
        let exp = document.getElementById('search-create-panel');
        console.log(exp);
        axios(view_searchjob_request(input, token))
            .then((result) => {
                setIsfetching(false);
                setJobs(result.data.body)

            })
            .catch(() => {
                setIsfetching(false);
                setError(true)
            });
    }

    const submitSearch = () => {

        let list = (values.indexOf(',') >= 0) ? values.split(',') : [values];
        let payload = {
            search_id: list,
            random_id: generateRecordID(user)
        }
        submitSearchAxios(payload);
    }

    const submitSearchAxios = (input) => {
        setValues('');
        appendData(input);

        axios(submit_search(input, token))
            .catch((result) => {
                if (result.response.status !== 503) {
                    setCreateError(true)
                }
            });
    }

    const appendData = (submitPayload) => {
        let temp = { "search_id": submitPayload.random_id, "status": "In Progress" };
        let array = [...jobs];
        array.unshift(temp);
        setJobs(array)
    }

    const handleChange = (e) => {
        const re = /^[A-Za-z0-9,]{18,94}/
        //console.log(re.test(e.target.value));
        setValues(e.currentTarget.value);
        if (re.test(e.target.value) && validateInput(e.target.value)) {
            setInputError(false)
        } else {
            setInputError(true)
        }

    }



    const refresh = () => {
        searchAxios(payload);
    }



    if (error) return (<StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." />)

    return (<Container maxWidth={false}
        sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            height: 'flex',
            alignContent: 'flex-start',
            spacing: 2,
            paddingRight: '10px!important',
            paddingLeft: '15px!important'

        }}
    ><Backdrop open={isFetching}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ flexGrow: 1, display:'flex', flexDirection:'row' }}>
            <Accordion sx={{width:'100%'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="search-create-panel"                   
                ><Box sx={{ display: 'flex', width: '100%' }}>
                        <Typography variant="body1" sx={{ marginRight: 'auto' }}>Search Remediation</Typography>

                        {createError && (<StatusMessage severity="error" message="Sorry, error encountered. Please retry after some time." />)}
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction='row' spacing={2}>
                        <Grid item lg={9}>
                            <TextField
                                error={inputError}
                                id="search_input"
                                label="Search"
                                placeholder="eg:0012N00000MWEWNQA5,0012N00000MWEWNQA6,0012N00000MWEWNQA7"
                                helperText='Max 5 Valid Salesforce 18 Characters Record IDs '
                                value={values}
                                size={'small'}
                                onChange={(e) => handleChange(e)}
                                sx={{ width: '100%', margin: 0 }}
                                fullWidth
                                multiline
                                required
                                inputProps={{
                                    maxLength: 94
                                }}
                            />
                        </Grid>
                        <Grid item lg={3}>

                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={submitSearch}
                                disabled={values === '' || inputError}
                                sx={{
                                    background: `linear-gradient(to right, #1488cc, #2b32b2)`, /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
                                }}
                            >
                                Submit
                            </Button>

                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            
                <Button sx={{paddingTop:'10px', paddingLeft:'5px'}}  data-testid='btn-refesh' onClick={() => { refresh() }}>
                <Tooltip title="Refresh Status"><RefreshIcon  style={{ color: green[300] }} /></Tooltip></Button>
            
        </Box>
        {(<Box sx={{ flexGrow: 1, marginTop: '20px' }}><Card sx={{ margin: 0 }}>
            <SearchTable isFetching={isFetching} jobs={jobs} />
        </Card>
        </Box>)}


    </Container>
    )
}

export default SearchJob;