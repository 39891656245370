const authConfig = {
  client_id: "378719d35b1c4b9183fe80c0a62be3e4",
  client_secret: "582bcaf36f0072fdb0233e4b8c2e4b5ffd75e0bb2b1d8f67060bc878df7be179",
  redirect_uri: "https://www.batchfactory-np.inside.telstra.com/auth",  
  response_type: "code",
  scope: "openid profile email phone address",
  authority: "https://sa.thyra.telstra.com/SecureAuth626/",
  silent_redirect_uri: "https://www.batchfactory-np.inside.telstra.com/auth",
  automaticSilentRenew: true, //optional, by default to true
  loadUserInfo: false, //optional, by default to true
  post_logout_redirect_uri: "https://www.batchfactory-np.inside.telstra.com/",
  checkSessionInterval: 10000, 
  revokeAccessTokenOnSignout: true, //invoke the revocation endpoint on signout
  metadata: ({
    issuer:"https://sa.thyra.telstra.com/SecureAuth626/",
    authorization_endpoint: "https://sa.thyra.telstra.com/SecureAuth626/SecureAuth.aspx",
    token_endpoint: "https://sa.thyra.telstra.com/SecureAuth626/OidcToken.aspx",
    userinfo_endpoint: "https://sa.thyra.telstra.com/SecureAuth626/OidcUserInfo.aspx",
    check_session_iframe: "https://sa.thyra.telstra.com/SecureAuth626/OidcCheckSession.aspx",
    introspection_endpoint: "https://sa.thyra.telstra.com/SecureAuth626/OAuthintrospect.aspx",
    end_session_endpoint: "https://sa.thyra.telstra.com/SecureAuth626/OidcEndSession.aspx",
    jwks_uri: "https://sa.thyra.telstra.com/SecureAuth626/.well-known/jwks"
  }),
  signingKeys: [
    {
      "kty": "RSA",
      "use": "sig",
      "e": "AQAB",
      "n": "tyJY_sDwJ44KOZX9acCoq4VrTx4ViluYAmQ50Sk8m7g-4ZPdcdEmYu_Y2WR1TxbVZkELuZZBwl3z5R_tiAO1wmEZ-Rhj10bgBqPrQHZSHUdYxQQP_xrpsIcE2DDBudrr_d0_VAoGvMi3Cy2om9iWA4GZExpMLLM1t1NYx4pDNnH7P72sTXAY9RCOoJubLgxqF20S7feiIi_69NvwbnvMHmcfKrsXVBFO97DUiagLcKt111ewDwpqh2Y3tat1N7wtOTp1pFP_PSvkYTcgWem3VNjG-JcNoioAaRsspItMWhTb6iU7z4p8BAO1GFym12aeMgqI9Ao5OZ8ccO3JtvgYSw",
      "alg": "HS256",
      "x5t": "H8pLaMMDd4lyfgleIoyW5QHXGb0",
      "kid": "H8pLaMMDd4lyfgleIoyW5QHXGb0"
    }
  ]
};

export default authConfig;
