import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
    Box,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    Stack,
    TableBody,
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import StatusMessage from '../components/common/statusMessage';
import * as Disclaimer from '../components/createjob/jobSummaryDisclaimer';
import UploadButton from '../components/common/uploadButton';
import { v4 as uuidv4 } from 'uuid';
import { decryptData } from '../utils/cipherhelper';


const columns = [
    { id: 'jobid', label: 'Job ID' },
    { id: 'description', label: 'Job Description', minWidth: '180px' },
    { id: 'system', label: 'System' },
    { id: 'object', label: 'Object' },
    { id: 'operation', label: 'Operation' },
    { id: 'frequency', label: 'Frequency' },
    { id: 'schedule', label: 'Schedule' },
    { id: 'issueid', label: 'Issue ID' },
    { id: 'user', label: 'Owner' },
    { id: 'upload', label: 'Upload File' }
];

const Header = ({ title }) => {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
            margin={1}
            sx={{ flex: '1 0 auto' }}

        >
            <Typography color="textPrimary" variant="h6" sx={{ marginTop: '-15px' }}>
                {title}
            </Typography>
        </Stack>
    )
}

const JobSummary = () => {
    const location = useLocation();
    const context = location.state
    const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Job Summary | BatchFactory</title>
            </Helmet>
            <Box
                sx={{
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'flex',
                    justifyContent: 'center',
                    padding: '15px',
                }}
            >
                <Grid
                    container
                    spacing={1.5}
                    sx={{ display: 'flex', maxWidth: '100%' }}
                >
                    <Grid
                        item
                        md={12}
                    >
                        <StatusMessage severity="success" message="BatchFactory Job Created" minwidth='97.3%' />
                        <Box
                            sx={{
                                borderRadius: 1.5,
                                backgroundColor: '#fff',
                                padding: '15px',
                                boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                                maxWidth: '100%'
                            }}>
                            <Header title="Job Details" />
                            <TableContainer sx={{ maxHeight: '100%', maxWidth: '100%', borderRadius: '1.5' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, backgroundColor: '#1b6ee2', color: '#fff', fontWeight: 'bold', paddingTop: '3px', paddingBottom: '3px' }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.jobid}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.description}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.system}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.object}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.operation}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.frequency}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.startdate} {context.enddate ? " to " + context.enddate : ''}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.issueid}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                {context.username}
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                <UploadButton user={oidcUser} data={context.jobid} sd={context.startdate} ed={context.enddate} freq={context.frequency}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        <Box
                            sx={{
                                borderRadius: 1.5,
                                backgroundColor: '#fff',
                                padding: '15px',
                                boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                                maxWidth: '100%'
                            }}>
                            <Header title="Guidelines" />
                            <Box sx={{ display: 'flex', direction: 'column' }}>

                                <TableContainer sx={{ maxHeight: '100%', maxWidth: '100%', borderRadius: '1.5' }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {Disclaimer.contents.map((column) => (
                                                    <TableCell
                                                        key={column.title}
                                                        align={'left'}
                                                        style={{ minWidth: column.minWidth, backgroundColor: '#1b6ee2', color: '#fff', fontWeight: 'bold', paddingTop: '3px', paddingBottom: '3px' }}
                                                    >
                                                        {column.title}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {Disclaimer.contents.map((item) => (
                                                    <TableCell key={uuidv4()} style={{ verticalAlign: 'top', paddingTop: '1px' }}>
                                                        {item.content.map((detail) => (
                                                            <p key={uuidv4()}>{detail}</p>
                                                        ))}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={12}>
                        <Box
                            sx={{
                                borderRadius: 1.5,
                                backgroundColor: '#fff',
                                padding: '15px',
                                boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                                maxWidth: '100%'
                            }}>
                            <Button
                                color="primary"
                                size="large"
                                type="submit"
                                variant="contained"
                                data-testid='btn-generate'
                                onClick={(e) => { e.preventDefault(); navigate('/app/generatetemplate', { replace: false }) }}
                                sx={{

                                    background: `linear-gradient(to right, #1488cc, #2b32b2)`, 
                                }}
                            >Generate Remediate Template</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default JobSummary;
