import React, { useState } from 'react';
import { Box, Tabs, Tab, Container, Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ShowJob from '../../views/ShowJob';
import Filter from './filter';
import axios from 'axios';
import * as Options from '../common/requestFormat';
import { useQuery } from 'react-query';
import {decryptData} from '../../utils/cipherhelper';

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div>
      {value === index && (<div>{children}</div>)}
    </div>
  )
}
const styles = {
  fontSize: '0.6rem'
};

async function fetchJobList(filter, token) {
  const { data } = await axios(Options.viewjob_request(filter, token))
  return data
}

const ViewJobRoot = () => {
  //const[ reset,setReset] = false;
  const page = 0;
  const [value, setvalue] = useState(0);  
  const initialJobs = [];
  const [jobs, setJobs] = useState(initialJobs);
  const [fulldata, setFullData] = useState(initialJobs);
  const [innerTabValue, setInnerTabValue] = useState(0);
  
  const oidcUser = decryptData(sessionStorage.getItem('oidcString'));
  const userid = oidcUser?.profile.sub;
  const token = oidcUser?.access_token;   
  const intialFilter = {
    "sort": {
      "sort_attribute": "startdate",
      "order": "desc"
    },
    "filter": {
      "filter_attribute": "N",
      "filter_key": "N"
    },
    "status": "current",
    "userid":userid
  }
  const [filter, setFilter] = useState(intialFilter);

  const {
    isFetching,
    error,
    refetch
  } = useQuery(
    ['projects', filter, token, page], () => fetchJobList(filter, token),
    { keepPreviousData: true, refetchOnWindowFocus: false,onSuccess: (data)=>{      
      if (data) {
        setJobs(data.body); 
        setFullData(data.body); 
        
      } else {
        setJobs(data?.body);  
        setFullData(data?.body);    
      }
    } }
  )  

  const handleTabs = (event, val) => {
    setvalue(val);
    if (val === 0) {
      setFilter(prevState => ({
        ...prevState,
        sort: prevState.sort,
        status: prevState.status,
        filter: prevState.filter,
        userid: userid
      }))
    } else {      
      let cleanState = {};
      cleanState =  Object.assign({}, filter);
      delete cleanState.userid;
      setFilter(cleanState);      
    }

  }

  const receiveFilter = () => {
    refetch();    
  }

  const handleSort = (property, sort) => {
    setFilter(prevState => ({
      ...prevState,
      sort: {
        "sort_attribute": property,
        "order": sort
      },
      status: prevState.status,
      filter: prevState.filter
    }))
  }
  const updateJobs=(filteredJobs)=>{
    setJobs(filteredJobs);
  }

  const handleInnerTabs = (event, val) => {
    setInnerTabValue(val);    
    let tab = event.currentTarget.childNodes[0].innerHTML.toLowerCase();
    setFilter(prevState => ({
      ...prevState,
      sort: prevState.sort,
      filter: prevState.filter,
      status: tab
    }))  
  }  
  return (
    <>
      <Helmet>
        <title>View Job | BatchFactory</title>
      </Helmet>
      <Container maxWidth={false}
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          height: 'flex',
          alignContent: 'flex-start',
          spacing: 2,
          paddingRight: '10px!important',
          paddingLeft: '15px!important'

        }}
      >
        <Box
          sx={{
            borderRadius: 1.5,
            backgroundColor: '#fff',
            padding: '15px',
            boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
            maxWidth: '100%'
          }}>
          <Grid container spacing={2} >
            <Grid item md={6} xs={12}>
              <Tabs value={value} onChange={handleTabs}>
                <Tab label="My Jobs" />
                <Tab label="All Jobs" />
              </Tabs>
            </Grid>
            <Grid item md={6} xs={12}><Filter filter={receiveFilter}  jobs={fulldata} filteredList={updateJobs}/></Grid>
            <Grid item md={12}>
              <TabPanel value={value} index={0}>
                <div>
                  <Tabs style={{ marginLeft: '20px' }} value={innerTabValue} onChange={handleInnerTabs}>
                    <Tab label={<span style={styles}>Current</span>} />
                    <Tab label={<span style={styles}>Hist</span>} />
                  </Tabs>
                  <TabPanel value={innerTabValue} index={0}><div>
                    <ShowJob isfetching={isFetching} error={error}  sortData={handleSort} index={value} page={page} filter={filter} jobs={jobs} />
                  </div>
                  </TabPanel>
                  <TabPanel value={innerTabValue} index={1}><div>
                    <ShowJob isfetching={isFetching} error={error} sortData={handleSort} index={value} page={page} filter={filter} jobs={jobs} />
                  </div>
                  </TabPanel>
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}><div>
                <Tabs style={{ marginLeft: '20px' }} value={innerTabValue} onChange={handleInnerTabs}>
                  <Tab label={<span style={styles}>Current</span>} />
                  <Tab label={<span style={styles}>Hist</span>} />
                </Tabs>
                <TabPanel value={innerTabValue} index={0}><div>
                  <ShowJob isfetching={isFetching ? true : false} error={error} sortData={handleSort} index={value} page={page} filter={filter} jobs={jobs} />
                </div>
                </TabPanel>
                <TabPanel value={innerTabValue} index={1}><div>
                  <ShowJob isfetching={isFetching ? true : false} error={error} sortData={handleSort}  index={value} page={page} filter={filter} jobs={jobs} />
                </div>
                </TabPanel>
              </div></TabPanel>
            </Grid>
          </Grid>

        </Box>
      </Container>
    </>
  )
}

export default ViewJobRoot;
