import React, { useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Button } from '@material-ui/core';
import * as Options from './requestFormat';
import axios from 'axios';
//import { useReactOidc } from '@axa-fr/react-oidc-context';
import StatusMessage from './statusMessage';
import { green } from '@material-ui/core/colors';
const validateDate = (file, sd, ed, freq) => {
  if(freq =='Adhoc'){
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const currentDate = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 10);    
    return file.includes(currentDate.replaceAll('-',''));
  } else {
    let dateInFile = file.split('_')[1];
    let sdInteger = sd.replaceAll('-', '');
    let edInteger = ed.replaceAll('-', '');
    return dateInFile >= sdInteger && dateInFile <= edInteger;
  }
  
}

const UploadButton = (props) => {
  //const { oidcUser } = useReactOidc();
  const oidcUser = props.user
  const [isLoading, setIsLoading] = useState(true);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [statusInput, setStatusInput] = useState(
    {
      severity: '',
      message: ''
    }
  );
  const updateStatus = (sev, msg, loading = false) => {
    setIsLoading(loading)
    setStatusInput({
      severity: sev,
      message: msg
    })
  }
  const handleSubmit = () => {
    updateStatus('info', 'File upload in progress...', true)

    const token = oidcUser.access_token;
    const user = oidcUser?.profile.sub;
    const role = oidcUser?.profile.groups;
    let fullFileName = fileToUpload.name;
    let fileName = fullFileName.substr(0, fullFileName.lastIndexOf('.'));

    if (validateDate(fileName, props.sd, props.ed, props.freq) && fileName.match(/^([cde](\d){6}-SF-(\w){5,8}_(\d){8})$/) && fullFileName.split('.').pop() === "csv" && fileName.includes(props.data) && (role.includes('CDMAdmin') || fileName.indexOf(user) != -1)) {
      axios(Options.signedurl_request(fileName, token))
        .then((result) => {
          if (result.status === 200) {
            axios(Options.upload_request(result, fileToUpload))
              .then(() => {
                updateStatus('info', 'File Upload Complete', true)
              })
              .catch(() => {
                updateStatus('error', 'Sorry upload failed...please click on Upload icon to retry')
              })
          }
          else {
            updateStatus('error', 'Sorry upload failed...please click on Upload icon to retry')
          }
        })
        .catch(() => {
          updateStatus('error', 'Sorry upload failed...please click on Upload icon to retry')
        });
    }
    else {
      updateStatus('error', 'Please check the filename and retry. Format <jobid_yyyymmdd.csv> File date must be a scheduled date for all jobs, except for Adhoc job - must be Current Date ONLY')
    }
  }
  return (
    <>
      <input
        type="file"
        accept=".csv, text/csv"
        data-testid="uploadInput"
        onChange={(e) => {
          setFileToUpload(e.target.files[0]);
          updateStatus('', '')
        }} style={{ width: '200px', wordBreak: 'break-word' }}
      />
      <Button
        variant="outlined"
        data-testid="uploadButton"
        component="label"
        disabled={isLoading}
        onClick={handleSubmit}
      >
        <CloudUploadIcon style={{ color: green[300] }} />
      </Button>
      {statusInput.severity !== '' &&
        <StatusMessage severity={statusInput.severity} message={statusInput.message} />
      }
    </>
  )
}

export default UploadButton;
