import React from 'react';
import { Grid } from '@material-ui/core';
import RingLoader from "react-spinners/RingLoader";


const Spinner = ()=> {
    const override = "margin-top:30px; padding-left:300px; display:inline-block";

    return (
        <Grid data-testid="spinner"
            container sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            height: 'flex',
            alignContent: 'flex-start',
            }}
            spacing={2}
        >
            <Grid item xs={12} sx={{ml:50, mt:10 }}>
                <RingLoader color={'#3ad7f0'} css={override} size={60} />
            </Grid>
        </Grid>
    )
}

export default Spinner;