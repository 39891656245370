import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import { useReactOidc } from '@axa-fr/react-oidc-context';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Link, Button, Tooltip
} from '@material-ui/core';

import UploadButton from '../components/common/uploadButton';
import EditIcon from '@material-ui/icons/Edit';
import { green } from '@material-ui/core/colors';

const ShowJobTable = (props) => {

    const navigate = useNavigate();

    const edit = (values) => {
        navigate('/app/editjob', { state: values, replace: true })
    }

    // const { oidcUser } = useReactOidc();    
    const role = props.user.profile.groups;
    const [sort, setSort] = useState('asc');
    function handleClick(jobid) {
        const path = '/app/detailedView/' + jobid;
        navigate(path, { replace: false });
    }
    const handleSort = (property, sort) => {
        (sort === 'asc') ? setSort('desc') : setSort('asc');
        props.sortHandler(property, sort);
    }


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Job Id
                    </TableCell>
                    {props.allJobs && (<TableCell>
                        <TableSortLabel direction={sort} onClick={() => { handleSort('username', sort) }}>
                            User
                        </TableSortLabel>
                    </TableCell>)}
                    <TableCell>
                        <TableSortLabel direction={sort} onClick={() => { handleSort('issueid', sort) }}>
                            Issue ID
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        Description
                    </TableCell>
                    <TableCell>
                        Remediated
                    </TableCell>
                    <TableCell>
                        <TableSortLabel direction={sort} onClick={() => { handleSort('operation', sort) }}>
                            Job Type
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel direction={sort} onClick={() => { handleSort('frequency', sort) }}>
                            Frequency
                        </TableSortLabel>
                    </TableCell>
                    <TableCell >
                        <TableSortLabel direction={sort} onClick={() => { handleSort('object', sort) }}>
                            Object
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection="desc">
                        <TableSortLabel direction={sort} onClick={() => { handleSort('startdate', sort) }}>
                            Start
                        </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection="desc">
                        <TableSortLabel direction={sort} onClick={() => { handleSort('enddate', sort) }}>
                            End
                        </TableSortLabel>
                    </TableCell>


                    {(props.filter.status === 'current') && (!props.allJobs || (props.allJobs && role.includes('CDMAdmin'))) && (
                        <><TableCell>
                            Edit
                        </TableCell><TableCell>
                                Upload File
                            </TableCell ></>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.jobs.map((job) => (
                    <TableRow
                        hover
                        key={job.jobid}
                    >
                        {(props.allJobs && role.includes('CDMAdmin')) && (<TableCell>
                            <Link href="#" data-testid='job_link' underline="hover" onClick={() => handleClick(job.jobid)}>
                                {job.jobid}
                            </Link>
                        </TableCell>)}
                        {(!props.allJobs) && (<TableCell>
                            <Link href="#" data-testid='job_link' underline="hover" onClick={() => handleClick(job.jobid)}>
                                {job.jobid}
                            </Link>
                        </TableCell>)}
                        {((props.allJobs) && !role.includes('CDMAdmin')) && (<TableCell>
                            {job.jobid}
                        </TableCell>)}
                        {(props.allJobs) && (<TableCell>
                            {job.username}
                        </TableCell>)}
                        <TableCell>
                            {job.issueid}
                        </TableCell>
                        <TableCell>
                            {job.description}
                        </TableCell>
                        <TableCell>
                            {job.remediation_count}
                        </TableCell>
                        <TableCell>
                            {job.operation}
                        </TableCell>
                        <TableCell>
                            {job.frequency}
                        </TableCell>
                        <TableCell style={{ wordBreak: 'break-all', paddingRight: 5 }}>
                            {job.object}
                        </TableCell>
                        <TableCell>
                            {job.startdate}
                        </TableCell>
                        <TableCell>
                            {job.enddate}
                        </TableCell>



                        {(props.filter.status === 'current' && ((props.allJobs && role.includes('CDMAdmin')) ||
                            (!props.allJobs)))
                            && (<><TableCell> <Button data-testid='btn-edit' onClick={() => { edit(job) }}>
                                <Tooltip title="Edit"><EditIcon style={{ color: green[300] }} /></Tooltip></Button></TableCell>
                                <TableCell><UploadButton user={props.user} data={job.jobid} sd={job.startdate} ed={job.enddate} freq={job.frequency} /> </TableCell>

                            </>)}

                    </TableRow>
                ))}
            </TableBody>
        </Table>

    )
};

export default ShowJobTable;
